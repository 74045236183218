import "@sweetalert2/theme-dark/dark.css";
//@ts-ignore
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import withReactContent from "sweetalert2-react-content";
import { SweetAlertResult } from "sweetalert2";
export const MercurySwal = withReactContent(Swal);

export const ShowDeleteModal = (item: string): Promise<SweetAlertResult> => {
  return MercurySwal.fire({
    title: "Are You Sure?",
    text: `Are Sure You Want to Delete ${item}`,
    icon: "warning",
    confirmButtonText: "Delete",
    showLoaderOnConfirm: true,
    showCancelButton: true,
  });
};

export const ShowNoticeModal = (message: string) => {
  return MercurySwal.fire({
    text: message,
    title: "Notice",
    confirmButtonText: "OK",
    showCancelButton: false,
    showLoaderOnConfirm: false,
  });
};

export const ShowConfirmModal = (title: string, message: string): Promise<SweetAlertResult> => {
  return MercurySwal.fire({
    text: message,
    title,
    confirmButtonText: "Continue",
    showLoaderOnConfirm: true,
    showCancelButton: true,
    footer: `<div styles="display: flex">
      <input type="checkbox" id="dontshowagaincheckbox" />
      <label>Don't show me again</label>
    </div>`,
    preConfirm: () => {
      const value: any = document.getElementById("dontshowagaincheckbox");
      if (value && value.checked) {
        localStorage.setItem("uploadDontShowAgain", "true");
      }
    },
  });
};

export const ErrorAlert = async (error: string) => {
  await MercurySwal.fire({
    title: "Error!",
    text: error,
    icon: "error",
    toast: true,
    position: "bottom-right",
    timer: 15000,
  });
};

export const SuccessAlert = async (message: string, duration?: number) => {
  await MercurySwal.fire({
    title: "Success!",
    text: message,
    icon: "success",
    toast: true,
    position: "bottom-right",
    timer: duration || 2000,
  });
};
