import { gql } from "apollo-boost";

const FarmGroupSensor = gql`
  query farmGroupSensorDropDown {
    farmGroupSensorDropDown {
      farmGroupName
      farmGroupId
      sensorName
      sensorId
    }
  }
`;

const FarmGroupSensorGroupSensorType = gql`
  query farmGroupSensorGroupSensorTypeDropDown {
    farmGroupSensorGroupSensorTypeDropDown {
      farmGroupName
      farmGroupId
      sensorGroup
      sensorGroupId
      sensorType
      sensorTypeId
    }
  }
`;

const GetFarmNames = gql`
  query getFarmNames($farmGroupID: ID!) {
    getFarmNames(farmGroupID: $farmGroupID) {
      name
      ID
    }
  }
`;

const GetFieldNames = gql`
  query getFieldNames($farmID: ID!) {
    getFieldNames(farmID: $farmID) {
      name
      ID
    }
  }
`;

const GetFields = gql`
  query getFields($farmId: ID) {
    getFields(farmId: $farmId) {
      FieldID
      FieldName
      FarmID
    }
  }
`;

const GetFarms = gql`
  query getFarms($farmGroupId: ID) {
    getFarms(farmGroupId: $farmGroupId) {
      FarmID
      FarmName
      FarmGroupID
    }
  }
`;

const GetFarmGroups = gql`
  query getFarmGroups {
    getFarmGroups {
      FarmGroupID
      FarmGroupName
      CompanyID
    }
  }
`;

const GetCropClasses = gql`
  query getCropClasses {
    getCropClasses {
      ID
      Title
    }
  }
`;

const GetCropTypes = gql`
  query getCropTypes($cropClassID: ID) {
    getCropTypes(cropClassID: $cropClassID) {
      ID
      Title
    }
  }
`;

const GetSubVariants = gql`
  query getSubVariants($cropClassID: ID!, $cropTypeID: ID!) {
    getSubVariants(cropClassID: $cropClassID, cropTypeID: $cropTypeID) {
      ID
      Title
    }
  }
`;

const GetVarieties = gql`
  query getVarieties($cropClassID: ID!, $cropTypeID: ID!) {
    getVarieties(cropClassID: $cropClassID, cropTypeID: $cropTypeID) {
      ID
      Title
    }
  }
`;

export const DropDownsGraphql = {
  FarmGroupSensor,
  FarmGroupSensorGroupSensorType,
  GetFarms,
  GetFieldNames,
  GetFarmNames,
  GetFarmGroups,
  GetCropClasses,
  GetCropTypes,
  GetSubVariants,
  GetVarieties,
  GetFields,
};
