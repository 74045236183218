import React, { useContext, useEffect } from "react";
import { ColumnContainer, RowContainer } from "@agxio/ui-kit";
import styled from "styled-components";
import { Charts } from "./Charts";
import { WeatherQueries } from "../../../../graphql/Weather";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { CircularProgress } from "@material-ui/core";
import { DropDownsGraphql } from "../../../../graphql";
import { DropDowns } from "../../components/DropDowns";
import { CentaurContext } from "../../../centaur/store/Context";

export default () => {
  const {
    states: { selectedDropDowns },
    actions,
  } = useContext(CentaurContext);

  const { data: farmGroups, loading } = useQuery(DropDownsGraphql.GetFarmGroups);
  const [getFarms, { data: farms, loading: farmLoading }] = useLazyQuery(DropDownsGraphql.GetFarms);

  const [getHistoricalData, { data: historicalData, loading: historicalLoading }] = useLazyQuery(
    WeatherQueries.GetHistoricalData,
  );

  useEffect(() => {
    if (selectedDropDowns.farm !== -1) {
      getHistoricalData({ variables: { farmID: selectedDropDowns.farm } });
    }
  }, [selectedDropDowns, getHistoricalData]);

  useEffect(() => {
    if (selectedDropDowns.farmGroup !== -1) {
      getFarms({ variables: { farmGroupId: selectedDropDowns.farmGroup } });
    }
  }, [selectedDropDowns.farmGroup, getFarms]);

  return (
    <ColumnContainer width="100%" height="100%" align="center" justify="flex-start" padding="0">
      <RowContainer width="100%" align="center" justify="flex-start">
        <RowContainer
          width="100%"
          height="50px"
          align="center"
          justify="flex-start"
          padding="0 2.5%"
          style={{ backgroundColor: "#2E2E2E" }}
        >
          <DropDowns
            farmGroups={farmGroups}
            farms={farms}
            onFarmGroupSelected={selected => {
              actions.changeSelectedDropDown("farmGroup", selected);
              actions.changeSelectedDropDown("farm", -1);
            }}
            onFarmSelected={selected => actions.changeSelectedDropDown("farm", Number(selected))}
            selectedFarm={selectedDropDowns.farm}
            selectedFarmGroup={selectedDropDowns.farmGroup}
          />
        </RowContainer>
      </RowContainer>
      <RowContainer width="100%" height="90%" align="center" justify="space-between" padding="1% 2.5%">
        <Box>
          <Charts
            weather={{
              min: historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.MinTemp]) : [],
              max: historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.MaxTemp]) : [],
              avg: historicalData
                ? historicalData.getHistoricData.map((item: any) => [item.Date, (item.MaxTemp + item.MinTemp) / 2])
                : [],
            }}
            rainFall={
              historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.TotalRainfall]) : []
            }
            dayAirFrost={
              historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.DaysAirFrost]) : []
            }
            sunshine={
              historicalData
                ? historicalData.getHistoricData.map((item: any) => [item.Date, item.SunshineDuration])
                : []
            }
          />
        </Box>
      </RowContainer>
      {(historicalLoading || loading || farmLoading) && (
        <CircularProgress style={{ position: "absolute", bottom: "2%", right: "1%" }} color="secondary" />
      )}
    </ColumnContainer>
  );
};

const Box = styled(ColumnContainer)`
  background-color: black;
  border: 1px solid white;
  width: 100%;
  height: 100%;
`;
