import React from "react";
import styled from "styled-components";
import BlockIcon from "@material-ui/icons/Block";
import { Paper } from "@material-ui/core";

export default () => {
  return (
    <Container>
      <StyledPaper>
        <div className="header">
          <BlockIcon />
          <h1>Not Found</h1>
        </div>
        <p>The URL You Are Looing for Does not Exist.</p>
      </StyledPaper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
`;

const StyledPaper = styled(Paper)`
  position: relative;
  width: 30%;
  background-color: #00625d;
  display: flex;
  flex-direction: column;
  color: #fff;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    background: #254344;
    padding-left: 10px;
    svg {
      margin-right: 10px;
    }
  }
  p {
    line-height: 1.8;
    padding: 20px;
  }
`;
