import React, { useEffect } from "react";
import { anychartRemoveContextItems } from "../../../../utils/Anychart";

interface Props {
  weather: any;
  rainFall: any[];
  dayAirFrost: any[];
  sunshine: any[];
}

var chart: anychart.charts.Stock | null = null;

const createChart = (props: Props) => {
  var preloader = anychart.ui.preloader();
  preloader.render(document.getElementById("mainchart")!);
  preloader.visible(true);

  if (chart) {
    chart.dispose();
    chart = null;
  }
  chart = anychart.stock();
  var rainfall = chart.plot(0);
  rainfall
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  rainfall.line().data(props.rainFall);
  rainfall.bounds(0, 0, "45%", "45%");
  rainfall.title({
    text: "Rainfall",
    fontColor: "white",
    fontWeight: "bold",
    width: "100%",
    background: "black",
  });
  rainfall.background("#000");
  rainfall.legend().enabled(false);
  chart.scroller().selectedFill("#1e1e1e").area(props.rainFall);

  var historicWeather = chart.plot(1);
  historicWeather
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  historicWeather.line().data(props.weather.min).name("Min");
  historicWeather.line().data(props.weather.max).name("Max");
  historicWeather.line().data(props.weather.avg).name("Avg");
  historicWeather.bounds("55%", 0, "45%", "45%");
  historicWeather.title({
    text: "Historic Weather",
    fontColor: "white",
    fontWeight: "bold",
    width: "100%",
    background: "black",
  });
  historicWeather.background("#000");
  chart.scroller().selectedFill("#1e1e1e").area(props.weather.avg);

  var sunshine = chart.plot(2);
  sunshine
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  sunshine.line().data(props.sunshine);
  sunshine.bounds(0, "50%", "45%", "45%");
  sunshine.title({
    text: "Sunshine",
    fontColor: "white",
    fontWeight: "bold",
    width: "100%",
    background: "black",
  });
  sunshine.background("#000");
  sunshine.legend().enabled(false);
  chart.scroller().selectedFill("#1e1e1e").area(props.sunshine);

  var frost = chart.plot(3);
  frost
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  frost.line().data(props.dayAirFrost);
  frost.bounds("55%", "50%", "45%", "45%");
  frost.title({
    text: "Frost",
    fontColor: "white",
    fontWeight: "bold",
    width: "100%",
    background: "black",
  });
  frost.background("#000");
  frost.legend().enabled(false);
  chart.scroller().selectedFill("#1e1e1e").area(props.dayAirFrost);

  chart.width("100%");
  chart.height("100%");
  chart.background({ fill: "black" });
  chart.tooltip(false);
  chart.contextMenu().itemsFormatter(anychartRemoveContextItems);
  chart.plotsManualBounds(true);
  chart.container("chartcontainer");
  chart.credits(false);
  chart.draw();

  preloader.visible(false);
};

export const Charts = (props: Props) => {
  useEffect(() => createChart(props), [props]);
  return <div style={{ width: "100%", height: "100%" }} id="chartcontainer" />;
};
