import React, { useEffect } from "react";
import { anychartRemoveContextItems } from "../../../../../utils/Anychart";

interface Props {
  color: string[];
}

let predictedMap: anychart.charts.TreeMap | null;
const getMap = (color: string[]) => {
  anychart.data.loadJsonFile(
    "https://cdn.anychart.com/samples/tree-map-charts/us-population/data.json",
    function (data) {
      if (predictedMap !== null && predictedMap !== undefined) predictedMap.dispose();
      predictedMap = null;
      var dataTree = anychart.data.tree(data, "as-table");
      predictedMap = anychart.treeMap(dataTree);
      var customColorScale = anychart.scales.ordinalColor();

      let colours = color;

      customColorScale.colors(colours);
      predictedMap.colorScale(customColorScale);
      predictedMap.selectionMode("none").colorScale(anychart.scales.linearColor(colours[0], colours[1]));

      predictedMap.hintDepth(1).hintOpacity(0.8);
      predictedMap.title().enabled(false);
      predictedMap.tooltip().enabled(false);
      predictedMap.background({ enabled: false });
      predictedMap.credits(false);
      predictedMap.contextMenu().itemsFormatter(anychartRemoveContextItems);
      predictedMap.container("predictedMap");
      predictedMap.draw(true);
    },
  );
};

export const PredictedMap = (props: Props) => {
  useEffect(() => getMap(props.color), [props.color]);
  return <div id="predictedMap" style={{ width: "100%", height: "100%" }} />;
};
