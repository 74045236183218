import { Types, State } from "./types";

export default (state: State, action: any): State => {
  switch (action.type) {
    case Types.SET_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: action.payload.open,
      };
    default:
      return state;
  }
};
