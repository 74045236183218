export type CentaurState = {
  selectedDropDowns: {
    farmGroup: number;
    sensorGroup: number;
    sensorType: number;
    sensor: number;
    farm: number;
    company: number;
    field: number;
    cropClass: number;
    cropType: number;
    variety: number;
    subVariant: number;
  };
  configuration: {
    activeTab: number;
  };
  yields: {
    activeTab: number;
  };
};

export enum ButtonType {
  COMPANIES,
  USER,
  FARM_GROUPS,
  FARMS,
  FIELDS,
  SENSOR_TYPES,
  Column_Types,
  SENSOR_RULES,
  SENSOR_GROUPS,
  SENSORS,

  // Yields
  RELATIVE_B_YIELDS,
  RELATIVE_B_DISEASE,
  DETAILED_VARIETY_PROFILE,
}

export enum ActiveTabType {
  CONFIGURATION = "configuration",
  YIELDS = "yields",
}

export type CentaurContextType = {
  states: CentaurState;
  actions: {
    changeSelectedDropDown: (type: string, id: number) => void;
    setActiveTab: (tabName: ActiveTabType, tab: ButtonType) => void;
  };
};

// Types
const CHANGE_SELECTED_DROPDOWN = "CHANGE_SELECTED_DROPDOWN";
const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const Types = {
  CHANGE_SELECTED_DROPDOWN,
  SET_ACTIVE_TAB,
};
