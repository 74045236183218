import { gql } from "apollo-boost";

const GetBaselineStrategies = gql`
  query getBaselineStrategies($fieldID: ID!) {
    getBaselineStrategies(fieldID: $fieldID) {
      baselineStrategyID
      cropTypeID
      cropType
      subVariantID
      subVariant
      varietyID
      variety
      trialID
      trial
      seedRate
      baselineDate
      emergenceModelID
      emergenceModel
      tillageModelID
      tillageModel
      harvestModelID
      harvestModel
      fertilisationModelID
      fertilisationModel
      flightMapModelID
      flightMapModel
      expectedYieldPerHa
    }
  }
`;

const GetBaselineStrategyDropDowns = gql`
  query getBaselineStrategyDropDowns {
    getBaselineStrategyDropDowns {
      cropTypes {
        ID
        Title
      }
      subvariants {
        ID
        Title
        Parent
      }
      varieties {
        ID
        Title
        Parent
      }
      trialModels {
        ID
        Title
      }
      emergenceModels {
        ID
        Title
      }
      tillageModels {
        ID
        Title
      }
      harvestModels {
        ID
        Title
      }
      fertilisations {
        ID
        Title
      }
      flightMapModels {
        ID
        Title
      }
    }
  }
`;

const SaveBaselineStrategy = gql`
  mutation saveBaselineStrategy($input: BaselineStrategyInput!) {
    saveBaselineStrategy(input: $input)
  }
`;

const DeleteBaselineStrategy = gql`
  mutation deleteBaselineStrategy($baselineStrategyID: ID!) {
    deleteBaselineStrategy(baselineStrategyID: $baselineStrategyID)
  }
`;

export const BaselineStrategyGraphql = {
  GetBaselineStrategies,
  GetBaselineStrategyDropDowns,
  SaveBaselineStrategy,
  DeleteBaselineStrategy,
};
