export type State = {
  openDrawer: boolean;
};

export type ContextType = {
  states: State;
  actions: {
    setOpenDrawer: (open: boolean) => void;
  };
};

// Types
const SET_OPEN_DRAWER = "SET_OPEN_DRAWER";

export const Types = {
  SET_OPEN_DRAWER,
};
