import React, { useContext, useEffect } from "react";
import { CircularLoading, Header, ListContainer, ListTable } from "@agxio/ui-kit";
import { CentaurContext } from "../../../store/Context";
import { YieldsGraphql } from "../../../../../graphql/Centaur/yields";
import { useLazyQuery } from "@apollo/react-hooks";

const RelativeByDisease = () => {
  const {
    states: { selectedDropDowns },
  } = useContext(CentaurContext);

  const [getData, { data, loading }] = useLazyQuery(YieldsGraphql.GetVarietyHeatMapByType);

  useEffect(() => {
    getData({
      variables: {
        cropTypeID: selectedDropDowns.cropType,
        subVariantID: selectedDropDowns.subVariant,
      },
    });
  }, [selectedDropDowns, getData]);

  return (
    <>
      <ListContainer margin="0 0 0 5px" width="calc(100% - 250px)" height="100%" justify="space-between">
        <Header start="#19A355" end="#056E34">
          <div>List List</div>
        </Header>
        <ListTable
          data={data ? data.getVarietyHeatMapByType : []}
          idColumn="varietyID"
          onSelectItem={() => {}}
          headers={[
            { title: "Variety", value: "cropVarietyName", width: "150px" },
            { title: "Mildew", value: "diseaseResMildew", width: "150px" },
            { title: "Yellow", value: "diseaseResYellow", width: "150px" },
            { title: "Brown", value: "diseaseResBrown", width: "150px" },
            { title: "Sep.N.", value: "diseaseResSeptoriaNodurum", width: "150px" },
            { title: "Sep.T.", value: "diseaseResSeptoriaTrifici", width: "150px" },
            { title: "Eyespot", value: "diseaseResEyespot", width: "150px" },
            { title: "Fusarium", value: "diseaseResFusarium", width: "150px" },
            { title: "Orange", value: "diseaseResOrangeMidge", width: "150px" },
            { title: "Rhynch.", value: "diseaseRhynchosporium", width: "150px" },
            { title: "NetBlotch.", value: "diseaseNetBlotch", width: "150px" },
            { title: "BaYMV", value: "diseaseBaYMV", width: "150px" },
            { title: "Rust", value: "diseaseCrownRust", width: "150px" },
          ]}
        />
      </ListContainer>
      {loading && <CircularLoading color="primary" />}
    </>
  );
};

export default RelativeByDisease;
