import { client } from "./apolloClient";
import { UserGraphQL } from "../graphql";
import { UserProfile } from "../types";

class Authentication {
  storeAuthInfo = (loginTokens: any, doRefresh: boolean = true) => {
    const { access_token, refresh_token } = loginTokens;
    localStorage.setItem("token", access_token);
    localStorage.setItem("refreshToken", refresh_token);
    const decodedToken = this.parseJwt(access_token || "");
    localStorage.setItem("fullName", decodedToken.profile.fullName);
    localStorage.setItem("expires_at", decodedToken.exp);
    doRefresh && window.location.reload();
  };

  logout = () => {
    this.clearInfo();
    client.mutate({
      mutation: UserGraphQL.IsLoggedIn,
    });
    return true;
  };

  isLoggedIn = async () => {
    const localExpireAt = localStorage.getItem("expires_at");
    const expiresAt = localExpireAt && JSON.parse(localExpireAt);
    if (expiresAt && Date.now() >= expiresAt * 1000) {
      const { access_token, refresh_token } = await this.handleRefresh();
      if (access_token && refresh_token) {
        Auth.storeAuthInfo({ access_token, refresh_token }, false);
      }
    }
    return expiresAt ? true : false;
  };

  clearInfo = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("refreshToken");
  };

  parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(c => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  };

  getToken = (): string => {
    return localStorage.getItem("token") || "";
  };

  getUserProfile = (): UserProfile => {
    const token = localStorage.getItem("token");
    const decodedToken = token && this.parseJwt(token || "");
    return decodedToken && this.isLoggedIn()
      ? (decodedToken as UserProfile)
      : {
          userId: -1,
          profile: {
            admin: false,
            fullName: "",
            email: "",
            role: "",
            language: {
              ID: "",
              Title: "",
            },
          },
          exp: 0,
          companyName: "",
          companyId: 0,
        };
  };

  handleRefresh = async () => {
    const refresh = localStorage.getItem("refreshToken");
    return client
      .mutate({
        mutation: UserGraphQL.UserRefreshToken,
        variables: { token: refresh },
      })
      .then(result => result.data.refreshToken);
  };
}

export const Auth = new Authentication();
