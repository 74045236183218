import { gql } from "apollo-boost";

const UserLogin = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      access_token
      refresh_token
    }
  }
`;

const UserRefreshToken = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      access_token
      refresh_token
    }
  }
`;

const UserLogout = gql`
  mutation logout {
    logout
  }
`;

const IsLoggedIn = gql`
  query isLoggedin {
    isLoggedin
  }
`;

export const UserGraphQL = {
  UserLogin,
  UserRefreshToken,
  UserLogout,
  IsLoggedIn,
};
