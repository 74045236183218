import React, { useContext, useEffect } from "react";
import { CircularLoading, ColumnContainer, RowContainer } from "@agxio/ui-kit";
import styled from "styled-components";
import YieldBackground from "../../../../../Assets/yieldbackground.png";
import { CentaurContext } from "../../../store/Context";
import { YieldsGraphql } from "../../../../../graphql/Centaur/yields";
import { useLazyQuery } from "@apollo/react-hooks";
import { BoxItem } from "./BoxItem";
import { COLORS } from "../../../../../utils/colors";

const DetailedVarietyProfile = () => {
  const {
    states: { selectedDropDowns },
  } = useContext(CentaurContext);

  const [getData, { data, loading }] = useLazyQuery(YieldsGraphql.GetVarietyProfileById);

  useEffect(() => {
    getData({
      variables: {
        variantID: selectedDropDowns.variety,
      },
    });
  }, [selectedDropDowns, getData]);

  if (data && !data.getVarietyProfileById[0]) {
    return null;
  }

  return (
    <ColumnContainer width="100%" height="100%" align="center" justify="flex-start" padding="0">
      <RowContainer width="100%" height="100%" align="center" justify="space-between">
        <Box color="black" flex="1">
          <TopBox flex="1.5" align="center" justify="space-between" width="100%">
            <ColumnContainer width="100%" height="100%" align="flex-start" justify="flex-start" padding="1% 5%">
              <span>Yield Factors</span>
              <LatestForecast width="90%" height="25%" align="top" justify="flex-start" />
            </ColumnContainer>
          </TopBox>
          <RowContainer margin="3% 0 0 0" padding="1% 5%" width="100%" flex="1" justify="space-between">
            <ColumnContainer height="100%" justify="space-evenly" align="flex-start">
              <label>
                <span>Regional Scope: </span>
                {(data && data.getVarietyProfileById[0].regionalScope) || "N/A"}
              </label>
              <label>
                <span>UK: </span>
                {(data && data.getVarietyProfileById[0].yieldFactorUK) || "N/A"}
              </label>
              <label>
                <span>North: </span>
                {(data && data.getVarietyProfileById[0].yieldFactorNorth) || "N/A"}
              </label>
              <label>
                <span>South: </span>
                {(data && data.getVarietyProfileById[0].yieldFactorSouth) || "N/A"}
              </label>
            </ColumnContainer>
            <ColumnContainer height="100%" justify="space-evenly" align="flex-start">
              <label>
                <span>East: </span>
                {(data && data.getVarietyProfileById[0].yieldFactorEast) || "N/A"}
              </label>
              <label>
                <span>West: </span>
                {(data && data.getVarietyProfileById[0].yieldFactorWest) || "N/A"}
              </label>
              <label>
                <span>Untreated Yield: </span>
                {data && parseFloat(data.getVarietyProfileById[0].untreatedGrainYield || 0).toFixed(2)}
              </label>
            </ColumnContainer>
          </RowContainer>
          <BottomBox justify="space-between" padding="1% 5%">
            <ColumnContainer height="100%" justify="space-between" align="flex-start" margin="2%" flex="1">
              <SmallBox>
                <span>Regional Match</span>
              </SmallBox>
              <SmallBox>
                <span>Within Risk Appetite</span>
              </SmallBox>
              <SmallBox color="#D32030">
                <span>Optimum Yield</span>
              </SmallBox>
            </ColumnContainer>
            <ColumnContainer height="100%" justify="space-between" align="flex-start" margin="2%" flex="1">
              <SmallBox>
                <span>Optimum Financials</span>
              </SmallBox>
              <SmallBox>
                <span>Sustainable</span>
              </SmallBox>
            </ColumnContainer>
          </BottomBox>
        </Box>
        <Box color="black" flex="1">
          <TopBox align="center" justify="space-between" width="100%">
            <ColumnContainer width="100%" align="flex-start" justify="flex-start" padding="1% 5%">
              <span>Disease Risk Sensitivity</span>
            </ColumnContainer>
          </TopBox>
          <ColumnContainer
            overflow="auto"
            margin="3% 0 0 0"
            padding="1% 5%"
            width="100%"
            flex="1"
            justify="space-between"
          >
            <BoxItem label="Mildew" value={data && data.getVarietyProfileById[0].diseaseResMildew} />
            <BoxItem label="Yellow Rust" value={data && data.getVarietyProfileById[0].diseaseResYellow} />
            <BoxItem label="Brown Rust" value={data && data.getVarietyProfileById[0].diseaseResBrown} />
            <BoxItem label="Septoria Nodurum" value={data && data.getVarietyProfileById[0].diseaseResSeptoriaNodurum} />
            <BoxItem label="Septoria Trefici" value={data && data.getVarietyProfileById[0].diseaseResSeptoriaTrifici} />
            <BoxItem label="Eyespot" value={data && data.getVarietyProfileById[0].diseaseResEyespot} />
            <BoxItem label="Fusarium" value={data && data.getVarietyProfileById[0].diseaseResFusarium} />
            <BoxItem label="Orange Midge" value={data && data.getVarietyProfileById[0].diseaseResOrangeMidge} />
            <BoxItem label="Rhynchosporium" value={data && data.getVarietyProfileById[0].diseaseRhynchosporium} />
            <BoxItem label="Net Blotch" value={data && data.getVarietyProfileById[0].diseaseNetBlotch} />
            <BoxItem label="YMV" value={data && data.getVarietyProfileById[0].diseaseBaYMV} />
            <BoxItem label="Crown Rust" value={data && data.getVarietyProfileById[0].diseaseCrownRust} />
          </ColumnContainer>
        </Box>
        <Box color="black" flex="1" justify="none">
          <TopBox align="center" justify="space-between" width="100%">
            <ColumnContainer width="100%" height="100%" align="flex-start" justify="flex-start" padding="1% 5%">
              <span>Characteristics</span>
            </ColumnContainer>
          </TopBox>
          <ColumnContainer width="100%" align="flex-start" justify="flex-start" padding="1% 3%">
            <RowContainer width="100%" justify="space-between" align="flex-start">
              <SmallBox margin="1%" color={getColour(data && data.getVarietyProfileById[0].uKBreadMaking)}>
                <span>UK Bread</span>
              </SmallBox>
              <SmallBox margin="1%" color={getColour(data && data.getVarietyProfileById[0].secondaryDiv)}>
                <span>UK Biscuit</span>
              </SmallBox>
              <SmallBox margin="1%" color={getColour(data && data.getVarietyProfileById[0].uKDistilling)}>
                <span>UK Distilling</span>
              </SmallBox>
            </RowContainer>
            <RowContainer width="100%" justify="space-between" align="flex-start">
              <SmallBox margin="1%" color={getColour(data && data.getVarietyProfileById[0].uKPBreadExport)}>
                <span>UKPB Export Bread</span>
              </SmallBox>
              <SmallBox margin="1%" color={getColour(data && data.getVarietyProfileById[0].uKSWheatExport)}>
                <span>UKS Wheat Export</span>
              </SmallBox>
            </RowContainer>
          </ColumnContainer>
          <ColumnContainer overflow="auto" margin="3% 0 0 0" padding="1% 5%" width="100%" justify="space-between">
            <BoxItem label="Endo. Texture:" value={data && data.getVarietyProfileById[0].endospermTexture} />
            <BoxItem label="Hagberg FN:" value={data && data.getVarietyProfileById[0].hagbergFallingNumber} />
            <BoxItem label="Protein:" value={data && data.getVarietyProfileById[0].proteinContent} />
            <BoxItem label="Specific Weight:" value={data && data.getVarietyProfileById[0].specificWeight} />
            <BoxItem label="Lodg. Resist:" value={data && data.getVarietyProfileById[0].resistancetoLodging} />
            <BoxItem
              label="Lodg. Resist (+ PGR):"
              value={data && data.getVarietyProfileById[0].resistancetoLodgingwithoutPGR}
            />
            <BoxItem label="Straw Length:" value={data && data.getVarietyProfileById[0].strawLength} />
            <BoxItem label="Stem Stiffness:" value={data && data.getVarietyProfileById[0].stemStiffness} />
            <BoxItem label="Stem Shortness:" value={data && data.getVarietyProfileById[0].stemShortness} />
            <BoxItem label="Plant Height:" value={data && data.getVarietyProfileById[0].plantHeight} />
            <BoxItem label="Oil Content:" value={data && data.getVarietyProfileById[0].oilContent} />
            <BoxItem label="Glucosinolate:" value={data && data.getVarietyProfileById[0].glucosinolate} />
            <BoxItem label="Early Flowering:" value={data && data.getVarietyProfileById[0].earlinessofFlowering} />
            <BoxItem label="Early Maturing:" value={data && data.getVarietyProfileById[0].earlinessofMaturity} />
          </ColumnContainer>
        </Box>
      </RowContainer>
      {loading && <CircularLoading color="primary" />}
    </ColumnContainer>
  );
};

const Box = styled(ColumnContainer)`
  border: 1px solid #333333;
  background-color: ${(props: { color: string }) => props.color};
  height: 100%;
  margin: 5px;
`;

const TopBox = styled(ColumnContainer)`
  width: 100%;
  flex: ${(props: { flex?: string }) => props.flex || 0};
  font-size: 16px;
`;

const LatestForecast = styled(ColumnContainer)`
  background-image: url(${YieldBackground});
  background-size: 100% 100%;
  width: 100%;
  height: 90%;
  border: 1px solid white;
  justify-content: flex-end;
  padding: 2%;
  margin-top: 5%;
`;

const BottomBox = styled(RowContainer)`
  border-top: solid 1px #333333;
  padding: 3%;
  width: 100%;
  background-color: #141414;
`;

interface SmallBoxProps {
  color?: string;
  margin?: string;
}
const SmallBox = styled.div`
  background-color: ${(props: SmallBoxProps) => props.color || "#0f9349"};
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: 40px;
  padding: 0.5%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props: SmallBoxProps) => props.margin};
`;

const getColour = (value: string) => {
  return !value || value !== "Y" ? COLORS.RED : COLORS.GREEN;
};

export default DetailedVarietyProfile;
