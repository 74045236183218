import { ColumnContainer, RowContainer, DropDown, PrimaryButton } from "@agxio/ui-kit";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { MuiThemeProvider, createMuiTheme, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { YieldsGraphql } from "../../../../graphql/Centaur/yields";
import { getYieldDropDown } from "../../../../utils/dropdowns";
import { CentaurContext } from "../../store/Context";
import { ActiveTabType, ButtonType } from "../../store/types";
import { List } from "./RelativeByYields";
import RelativeByDisease from "./RelativeByDisease";
import DetailedVarietyProfile from "./DetailedVarietyProfile";

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
    background: {
      paper: "#fff",
    },
  },
  typography: {},
});

const items = [
  {
    key: ButtonType.RELATIVE_B_YIELDS,
    name: "Relative by Yields",
    component: List,
  },
  {
    key: ButtonType.RELATIVE_B_DISEASE,
    name: "Relative by Disease",
    component: RelativeByDisease,
  },
  {
    key: ButtonType.DETAILED_VARIETY_PROFILE,
    name: "Detailed Variety Profile",
    component: DetailedVarietyProfile,
  },
];

export default () => {
  const {
    states: { yields, selectedDropDowns },
    actions: { setActiveTab, changeSelectedDropDown },
  } = useContext(CentaurContext);
  const { data: cropClasses, loading } = useQuery(YieldsGraphql.GetCropClasses);
  const [getCropTypes, { data: cropTypes, loading: cropTypeLoading }] = useLazyQuery(YieldsGraphql.GetCropTypes);
  const [getSubVariants, { data: subVariants, loading: subVariantsLoading }] = useLazyQuery(
    YieldsGraphql.GetSubVariants,
  );
  const [getVarieties, { data: varieties, loading: varietiessLoading }] = useLazyQuery(YieldsGraphql.GetVarieties);

  useEffect(() => {
    if (selectedDropDowns.cropClass !== -1) {
      getCropTypes({
        variables: {
          cropClassID: selectedDropDowns.cropClass,
        },
      });
    }
    if (selectedDropDowns.cropType !== -1) {
      getSubVariants({
        variables: {
          cropTypeID: selectedDropDowns.cropType,
        },
      });
      yields.activeTab === ButtonType.DETAILED_VARIETY_PROFILE &&
        getVarieties({
          variables: {
            cropTypeID: selectedDropDowns.cropType,
          },
        });
    }
  }, [selectedDropDowns, getCropTypes, getSubVariants, getVarieties, yields]);

  return (
    <MuiThemeProvider theme={theme}>
      <ColumnContainer width="100%" height="100%" justify="flex-start" padding="0">
        <RowContainer width="100%" justify="flex-start" padding="10px 2.5%" style={{ backgroundColor: "#2E2E2E" }}>
          <DropDown
            label="Crop Class:"
            onSelect={selected => {
              changeSelectedDropDown("cropClass", selected);
              changeSelectedDropDown("cropType", -1);
            }}
            options={getYieldDropDown.getCropClassItems(cropClasses)}
            style={{ width: "30.5%" }}
            selectorStyle={{ marginLeft: "1%" }}
            selected={selectedDropDowns.cropClass}
          />
          <DropDown
            label="Crop Type:"
            onSelect={selected => {
              changeSelectedDropDown("cropType", selected);
              changeSelectedDropDown("subVariant", -1);
            }}
            options={getYieldDropDown.getCropTypeItems(cropTypes)}
            style={{ width: "33.2%", marginLeft: "1.5%" }}
            selectorStyle={{ marginLeft: "1%" }}
            selected={selectedDropDowns.cropType}
          />
          {yields.activeTab !== ButtonType.DETAILED_VARIETY_PROFILE && (
            <DropDown
              label="SubVariant:"
              onSelect={selected => {
                changeSelectedDropDown("subVariant", selected);
              }}
              options={getYieldDropDown.getSubVarientItems(subVariants)}
              style={{ width: "35.9%", marginLeft: "2%" }}
              selectorStyle={{ marginLeft: "1%" }}
              selected={selectedDropDowns.subVariant}
            />
          )}
          {yields.activeTab === ButtonType.DETAILED_VARIETY_PROFILE && (
            <DropDown
              label="Variety:"
              onSelect={selected => {
                changeSelectedDropDown("variety", selected);
              }}
              options={getYieldDropDown.getVarietyItems(varieties)}
              style={{ width: "35.9%", marginLeft: "2%" }}
              selectorStyle={{ marginLeft: "1%" }}
              selected={selectedDropDowns.variety}
            />
          )}
        </RowContainer>
        <RowContainer width="95%" height="90%" justify="flex-start" padding="10px 0 0 0 ">
          <ColumnContainer width="250px" margin="0 5px 0 0" height="100%" justify="space-between">
            <ColumnContainer padding="0 10px 0 0" width="100%" height="100%" justify="flex-start" overflow="auto">
              {items.map((item, key) => (
                <CustomButtons
                  key={key}
                  active={yields.activeTab === item.key}
                  onClick={() => setActiveTab(ActiveTabType.YIELDS, item.key)}
                >
                  {item.name}
                </CustomButtons>
              ))}
            </ColumnContainer>
          </ColumnContainer>
          {items
            .filter(x => x.key === yields.activeTab)
            .map((item, key) => (
              <item.component key={key} />
            ))}
        </RowContainer>
        {(loading || cropTypeLoading || subVariantsLoading || varietiessLoading) && (
          <CircularProgress style={{ position: "absolute", bottom: "2%", right: "1%" }} color="primary" />
        )}
      </ColumnContainer>
    </MuiThemeProvider>
  );
};

const CustomButtons = styled(PrimaryButton)`
  width: 100%;
  background: ${(props: { active: boolean }) =>
    props.active
      ? `linear-gradient(180deg, #ECF8F2 0%, #D6F2E4 100%)`
      : `linear-gradient(180deg, #19A355 0%, #056E34 100%)`};
  color: ${(props: { active: boolean }) => (props.active ? "#0C8140" : "white")};
  padding: 8% 2px;
  margin: 0 0 5% 0;
  font-size: 16px;
`;
