import React from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./utils/apolloClient";
import "anychart";
import { Auth } from "./utils/Auth";
import { Login } from "./commonPages/Login";
import UserRoutes from "./UserRoutes";
import { Alert } from "@agxio/ui-kit";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
    background: {
      paper: "#fff",
    },
  },
  typography: {},
});

export class App extends React.Component<any, any> {
  state = {
    loggedIn: true,
  };

  componentDidMount = () => {
    anychart.licenseKey("agxio.com-473bcf50-34a7cd02");

    Auth.isLoggedIn().then(loggedIn => {
      this.setState({ loggedIn });
    });
  };

  render() {
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ApolloProvider client={client}>{!this.state.loggedIn ? <Login /> : <UserRoutes />}</ApolloProvider>
          <Alert />
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}
