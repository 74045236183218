import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split, ApolloLink } from "apollo-boost";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { config } from "./config";
import { Auth } from "./Auth";
import { fromPromise } from "apollo-link";

let isRefreshing = false;
let pendingRequests: any = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback: Function) => callback());
  pendingRequests = [];
};

const errorLink = onError(({ forward, graphQLErrors, networkError = {}, operation, response }): any => {
  if (graphQLErrors && graphQLErrors.filter(e => e).length > 0) {
    for (const err of graphQLErrors) {
      if (err.message.includes("Authentication token is invalid, please log in")) {
        let forward$;
        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            Auth.handleRefresh()
              .then(({ access_token, refresh_token }) => {
                Auth.storeAuthInfo({ access_token, refresh_token }, false);
                resolvePendingRequests();
                return { access_token, refresh_token };
              })
              .catch(error => {
                pendingRequests = [];
                Auth.clearInfo();
                window.location.reload();
                return;
              })
              .finally(() => {
                isRefreshing = false;
              }),
          );
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise(resolve => {
              pendingRequests.push(() => resolve());
            }),
          );
        }
        return forward$.flatMap(() => forward(operation));
      }
    }
  }
});

const httpLink = createUploadLink({
  uri: config.graphQL,
});
const cache = new InMemoryCache({
  dataIdFromObject: object => object.id,
});

const wsLink = new WebSocketLink({
  uri: config.graphQL.replace(/http|https/, "ws"),
  options: {
    reconnect: true,
  },
});

const authLink = setContext(async (_, { headers }) => {
  var token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink),
);

const links = [errorLink, link];

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from(links),
});

export const removedTypeProp = (obj: any) =>
  Object.fromEntries(Object.entries(obj).filter(([key, val]) => key !== "__typename"));
