import React, { useContext, useEffect } from "react";
import { ColumnContainer, RowContainer } from "@agxio/ui-kit";
import styled from "styled-components";
import { Charts } from "./Charts";
import { Description } from "./Description";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { WeatherQueries } from "../../../../graphql/Weather";
import { CircularProgress } from "@material-ui/core";
import { DropDownsGraphql } from "../../../../graphql";
import { DropDowns } from "../../components/DropDowns";
import { CentaurContext } from "../../../centaur/store/Context";

export default () => {
  const {
    states: { selectedDropDowns },
    actions,
  } = useContext(CentaurContext);

  const { data: farmGroups, loading } = useQuery(DropDownsGraphql.GetFarmGroups);
  const [getFarms, { data: farms, loading: farmLoading }] = useLazyQuery(DropDownsGraphql.GetFarms);

  const [getHistoricalData, { data: historicalData, loading: historicalLoading }] = useLazyQuery(
    WeatherQueries.GetHistoricalData,
  );
  const [getTextualData, { data: textualData, loading: textualLoading }] = useLazyQuery(
    WeatherQueries.GetTexualForecast,
  );

  useEffect(() => {
    if (selectedDropDowns.farm !== -1) {
      getHistoricalData({ variables: { farmID: selectedDropDowns.farm } });
      getTextualData({ variables: { farmID: selectedDropDowns.farm } });
    }
  }, [selectedDropDowns, getHistoricalData, getTextualData]);

  useEffect(() => {
    if (selectedDropDowns.farmGroup !== -1) {
      getFarms({ variables: { farmGroupId: selectedDropDowns.farmGroup } });
    }
  }, [selectedDropDowns.farmGroup, getFarms]);

  return (
    <ColumnContainer width="100%" height="100%" align="center" justify="flex-start">
      <RowContainer width="100%" align="center" justify="flex-start">
        <RowContainer
          width="100%"
          height="50px"
          align="center"
          justify="flex-start"
          padding="0 2.5%"
          style={{ backgroundColor: "#2E2E2E" }}
        >
          <DropDowns
            farmGroups={farmGroups}
            farms={farms}
            onFarmGroupSelected={selected => {
              actions.changeSelectedDropDown("farmGroup", selected);
              actions.changeSelectedDropDown("farm", -1);
            }}
            onFarmSelected={selected => actions.changeSelectedDropDown("farm", Number(selected))}
            selectedFarm={selectedDropDowns.farm}
            selectedFarmGroup={selectedDropDowns.farmGroup}
          />
        </RowContainer>
      </RowContainer>
      <RowContainer width="100%" height="90%" align="center" justify="space-between" padding="1% 2.5%">
        <Box flex="1.05" margin="0 5px 0 0">
          <Charts
            temperatures={
              historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.MinTemp]) : []
            }
            rainfalls={
              historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.TotalRainfall]) : []
            }
          />
        </Box>
        <Box flex="3" align="flex-start" justify="flex-start" padding="1% 0">
          {textualData && textualData.getTextualForecast[0] && (
            <>
              <Description title="Day 1 to 2" text={textualData.getTextualForecast[0].Day1to2ForecastText} />
              <Description title="Day 3 to 5" text={textualData.getTextualForecast[0].Day3to5ForecastText} />
              <Description title="Day 6 to 15" text={textualData.getTextualForecast[0].Day6to15ForecastText} />
              <Description title="Day 15 to 30" text={textualData.getTextualForecast[0].Day16to30ForecastText} />
            </>
          )}
        </Box>
      </RowContainer>
      {(historicalLoading || textualLoading || loading || farmLoading) && (
        <CircularProgress style={{ position: "absolute", bottom: "2%", right: "1%" }} color="primary" />
      )}
    </ColumnContainer>
  );
};

const Box = styled(ColumnContainer)`
  background-color: black;
  border: 1px solid white;
  height: 100%;
  overflow-y: auto;
`;
