import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { Layout } from "./sharedComponents/Layout";
import { routesCurrentUserHasAccessTo } from "./routes";
import { RouteItem } from "./types";
import { ProjectStoreProvider } from "./services/ProjectContextApi/Context";
import Home from "./modules/centaur/tabs/Home";
import { CentaurProvider } from "./modules/centaur/store/Context";
import NotAuthorized from "./commonPages/NotAuthorized";

export default () => {
  const [allowedRoutes, setAllowedRoutes] = useState<RouteItem[]>([]);
  useEffect(() => {
    getAllowedRoutes();
  }, []);
  const getAllowedRoutes = async () => {
    const routes = await routesCurrentUserHasAccessTo();
    setAllowedRoutes(routes as RouteItem[]);
  };

  return (
    <ProjectStoreProvider>
      <CentaurProvider>
        <Layout>
          <Switch>
            {allowedRoutes &&
              allowedRoutes.map((route: RouteItem) => {
                const Component = route.component;
                return (
                  <Route
                    key={route.title}
                    path={route.path}
                    render={({ match: { url } }) => <Component baseUrl={url} />}
                  />
                );
              })}

            <Route exact path="/" component={Home} />
            <Route path="*" component={NotAuthorized} />
          </Switch>
        </Layout>
      </CentaurProvider>
    </ProjectStoreProvider>
  );
};
