import React from "react";
import { BaselineStrategyType } from "../../../../types/commonTypes";
import { Formik, FormikProps, Field } from "formik";
import styled from "styled-components";
import { BaselineStrategySchema } from "./Schema";
import {
  ColumnContainer,
  Form,
  FormButtonControl,
  FormContent,
  FormFooter,
  Header,
  PrimaryButton,
  RowContainer,
} from "@agxio/ui-kit";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";

interface Props {
  baselineStrategy?: BaselineStrategyType;
  dropdowns: any;
  onNew: () => void;
  fieldID: number;
  saveBaselineStrategy: (input: any) => Promise<boolean>;
  deleteBaselineSt: () => void;
}

const emptyModel: BaselineStrategyType = {
  cropTypeID: 0,
  subVariantID: 0,
  varietyID: 0,
  trialID: 0,
  seedRate: 0,
  emergenceModelID: 0,
  tillageModelID: 0,
  harvestModelID: 0,
  fertilisationModelID: 0,
  flightMapModelID: 0,
  expectedYieldPerHa: 0,
  baselineDate: "",
};

export const AddBaselineStrategy = (props: Props) => {
  return (
    <FormContent padding="15px 15px 1px 15px" style={{ color: "black" }}>
      <Formik
        enableReinitialize={true}
        initialValues={props.baselineStrategy || emptyModel}
        onSubmit={async (values: BaselineStrategyType, { resetForm }) => {
          if (
            await props.saveBaselineStrategy({
              fieldID: Number(props.fieldID),
              cropTypeID: Number(values.cropTypeID),
              emergenceModelID: Number(values.emergenceModelID),
              fertilisationModelID: Number(values.fertilisationModelID),
              flightMapModelID: Number(values.flightMapModelID),
              harvestModelID: Number(values.harvestModelID),
              subVariantID: Number(values.subVariantID),
              tillageModelID: Number(values.tillageModelID),
              trialID: Number(values.trialID),
              varietyID: Number(values.varietyID),
              seedRate: Number(values.seedRate),
              baselineDate: values.baselineDate,
              baselineStrategyID: values.baselineStrategyID,
            })
          ) {
            resetForm();
          }
        }}
        validationSchema={BaselineStrategySchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }: FormikProps<BaselineStrategyType>) => {
          return (
            <Form id="form" onSubmit={handleSubmit} style={{ flex: 1, width: "100%" }}>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Crop Type</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.cropTypeID}
                  inputProps={{ name: "cropTypeID" }}
                  error={touched.cropTypeID && !!errors.cropTypeID}
                >
                  {props.dropdowns &&
                    props.dropdowns.cropTypes &&
                    props.dropdowns.cropTypes.map((option: any) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.Title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Sub-Variety</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.subVariantID}
                  inputProps={{ name: "subVariantID" }}
                  error={touched.subVariantID && !!errors.subVariantID}
                >
                  {props.dropdowns &&
                    props.dropdowns.subvariants &&
                    props.dropdowns.subvariants
                      .filter((item: any) => item.Parent === String(values.cropTypeID))
                      .map((option: any) => (
                        <MenuItem key={option.ID} value={option.ID}>
                          {option.Title}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Variety</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.varietyID}
                  inputProps={{ name: "varietyID" }}
                  error={touched.varietyID && !!errors.varietyID}
                >
                  {props.dropdowns &&
                    props.dropdowns.varieties &&
                    props.dropdowns.varieties
                      .filter(
                        (item: any) =>
                          item.Parent.split(",")[0] === String(values.cropTypeID) &&
                          item.Parent.split(",")[1] === String(values.subVariantID),
                      )
                      .map((option: any) => (
                        <MenuItem key={option.ID} value={option.ID}>
                          {option.Title}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Trial Goal</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.trialID}
                  inputProps={{ name: "trialID" }}
                  error={touched.trialID && !!errors.trialID}
                >
                  {props.dropdowns &&
                    props.dropdowns.trialModels &&
                    props.dropdowns.trialModels.map((option: any) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.Title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  error={touched.baselineDate && !!errors.baselineDate}
                  name={"baselineDate"}
                  label={"Baseline Date"}
                  value={new Date(Number(values.baselineDate)).toJSON().split("T")[0]}
                  onChange={event => {
                    setFieldValue("baselineDate", new Date(event.target.value).getTime().toString());
                  }}
                  color="secondary"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  error={touched.seedRate && !!errors.seedRate}
                  name={"seedRate"}
                  label={"Seed Rate(t/ha)"}
                  value={values.seedRate}
                  onChange={handleChange}
                  color="secondary"
                  type="number"
                />
              </FormControl>
              <Header style={{ backgroundColor: "#C5E1B5", width: "100%", marginTop: "10px" }}>
                <div>Parameters</div>
              </Header>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Emergence Model</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.emergenceModelID}
                  inputProps={{ name: "emergenceModelID" }}
                  error={touched.emergenceModelID && !!errors.emergenceModelID}
                >
                  {props.dropdowns &&
                    props.dropdowns.emergenceModels &&
                    props.dropdowns.emergenceModels.map((option: any) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.Title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">tillage Model</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.tillageModelID}
                  inputProps={{ name: "tillageModelID" }}
                  error={touched.tillageModelID && !!errors.tillageModelID}
                >
                  {props.dropdowns &&
                    props.dropdowns.tillageModels &&
                    props.dropdowns.tillageModels.map((option: any) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.Title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Harvest Model</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.harvestModelID}
                  inputProps={{ name: "harvestModelID" }}
                  error={touched.harvestModelID && !!errors.harvestModelID}
                >
                  {props.dropdowns &&
                    props.dropdowns.harvestModels &&
                    props.dropdowns.harvestModels.map((option: any) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.Title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Fertilisation</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.fertilisationModelID}
                  inputProps={{ name: "fertilisationModelID" }}
                  error={touched.fertilisationModelID && !!errors.fertilisationModelID}
                >
                  {props.dropdowns &&
                    props.dropdowns.fertilisations &&
                    props.dropdowns.fertilisations.map((option: any) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.Title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel color="secondary">Flight Map Model</InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.flightMapModelID}
                  inputProps={{ name: "flightMapModelID" }}
                  error={touched.flightMapModelID && !!errors.flightMapModelID}
                >
                  {props.dropdowns &&
                    props.dropdowns.flightMapModels &&
                    props.dropdowns.flightMapModels.map((option: any) => (
                      <MenuItem key={option.ID} value={option.ID}>
                        {option.Title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormFooter>
                <RowContainer width="100%" justify="space-between">
                  <FormButtonControl margin="normal">
                    <PrimaryButton type="button" disabled={false} onClick={_ => props.onNew()}>
                      NEW
                    </PrimaryButton>
                  </FormButtonControl>
                  <FormButtonControl margin="normal">
                    <PrimaryButton disabled={props.fieldID === -1} type="submit">
                      SAVE
                    </PrimaryButton>
                  </FormButtonControl>
                  <FormButtonControl margin="normal">
                    <PrimaryButton
                      type="button"
                      disabled={!props.baselineStrategy}
                      onClick={async _ => props.deleteBaselineSt()}
                    >
                      DELETE
                    </PrimaryButton>
                  </FormButtonControl>
                </RowContainer>
              </FormFooter>
            </Form>
          );
        }}
      </Formik>
    </FormContent>
  );
};

const SelectRow = styled(RowContainer)`
  width: 100%;
  margin: 5px 0;
  height: 25px;
  justify-content: space-between;
  align-items: center;

  label {
    width: 39%;
  }

  select {
    width: 59%;
    height: 100%;
  }

  input {
    width: 59%;
    height: 100%;
  }
`;
