import React, { useContext, useEffect } from "react";
import { ColumnContainer, RowContainer } from "@agxio/ui-kit";
import WeatherBack from "../../../../Assets/weatherback.png";
import styled from "styled-components";
import { TempGauge } from "../../components/TempGauge";
import { DailyTempLineChart } from "./DailyTempLineChart";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { WeatherQueries } from "../../../../graphql/Weather";
import { CircularProgress } from "@material-ui/core";
import { DropDownsGraphql } from "../../../../graphql";
import { DropDowns } from "../../components/DropDowns";
import { RHWContainer } from "../../components/RHW";
import { CentaurContext } from "../../../centaur/store/Context";

export default () => {
  const {
    states: { selectedDropDowns },
    actions,
  } = useContext(CentaurContext);

  const { data: farmGroups, loading } = useQuery(DropDownsGraphql.GetFarmGroups);
  const [getFarms, { data: farms, loading: farmLoading }] = useLazyQuery(DropDownsGraphql.GetFarms);

  const [get5dayForecast, { data: forecastData, loading: forecastLoading }] = useLazyQuery(
    WeatherQueries.GetFiveDayWeatherForecast,
  );

  useEffect(() => {
    if (selectedDropDowns.farm !== -1) {
      get5dayForecast({ variables: { farmID: selectedDropDowns.farm } });
    }
  }, [selectedDropDowns.farm, get5dayForecast]);

  useEffect(() => {
    if (selectedDropDowns.farmGroup !== -1) {
      getFarms({ variables: { farmGroupId: selectedDropDowns.farmGroup } });
    }
  }, [selectedDropDowns.farmGroup, getFarms]);

  return (
    <ColumnContainer width="100%" height="100%" align="center" justify="flex-start" padding="0">
      <RowContainer
        width="100%"
        height="50px"
        align="center"
        justify="flex-start"
        padding="0 2.5%"
        style={{ backgroundColor: "#2E2E2E" }}
      >
        <DropDowns
          farmGroups={farmGroups}
          farms={farms}
          onFarmGroupSelected={selected => {
            actions.changeSelectedDropDown("farmGroup", selected);
            actions.changeSelectedDropDown("farm", -1);
          }}
          onFarmSelected={selected => actions.changeSelectedDropDown("farm", Number(selected))}
          selectedFarm={selectedDropDowns.farm}
          selectedFarmGroup={selectedDropDowns.farmGroup}
        />
      </RowContainer>
      <ColumnContainer width="100%" height="calc(100% - 50px)" padding="1% 2.5%">
        <MainContainer>
          <BackImage src={WeatherBack} />
          <TodayContainer>
            <ColumnContainer width="7%" height="100%" align="start" justify="start">
              <DayLabel>TODAY</DayLabel>
            </ColumnContainer>
            <RowContainer height="100%" width="15%" justify="flex-start">
              <TempGauge
                value={
                  forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].feelsLikeDayTemperature
                }
              />
              <span style={{ fontSize: "46px", fontWeight: 600, color: "#15A357" }}>
                {forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].feelsLikeDayTemperature}
                ° C
              </span>
            </RowContainer>
            <ColumnContainer width="25%" margin="0 10% 0 5%" height="100%">
              <DailyTempLineChart
                days={
                  forecastData
                    ? forecastData.getFiveDayWeatherForecast.map((x: any) => x.feelsLikeDayTemperature)
                    : [0, 0, 0, 0, 0]
                }
              />
            </ColumnContainer>
            <ColumnContainer
              width="40%"
              height="100%"
              align="flex-start"
              justify="space-between"
              style={{ borderLeft: "1px solid #232323" }}
            >
              <RowContainer width="100%" padding="0 0 0 2.5%" height="20%" justify="flex-start" align="flex-start">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {forecastData &&
                    forecastData.getFiveDayWeatherForecast[0] &&
                    forecastData.getFiveDayWeatherForecast[0].weatherType}{" "}
                </span>
              </RowContainer>
              <RowContainer height="75%" width="100%" justify="flex-start" align="flex-end">
                <RHWContainer
                  rain={
                    forecastData &&
                    forecastData.getFiveDayWeatherForecast[0] &&
                    forecastData.getFiveDayWeatherForecast[0].precipitationProbabilityDay
                  }
                  humidity={
                    forecastData &&
                    forecastData.getFiveDayWeatherForecast[0] &&
                    forecastData.getFiveDayWeatherForecast[0].humidityNoon
                  }
                  wind={
                    forecastData &&
                    forecastData.getFiveDayWeatherForecast[0] &&
                    forecastData.getFiveDayWeatherForecast[0].windSpeed
                  }
                  windDirection={
                    forecastData &&
                    forecastData.getFiveDayWeatherForecast[0] &&
                    forecastData.getFiveDayWeatherForecast[0].windDirection
                  }
                />
              </RowContainer>
            </ColumnContainer>
          </TodayContainer>
          <RowContainer width="100%" height="45%" align="flex-start" justify="space-between">
            {Array(4)
              .fill(0)
              .map((x, key) => {
                const day = forecastData && forecastData.getFiveDayWeatherForecast[key + 1];
                return (
                  <DayContainer key={key}>
                    <DayHeader>
                      <DayLabel>
                        +{key + 1} DAY{key > 0 && "S"}
                      </DayLabel>
                      <TempLabel>
                        {day && day.feelsLikeDayTemperature}
                        <span>° C</span>
                      </TempLabel>
                    </DayHeader>
                    <DayBody>
                      <RowContainer width="100%" height="60%" justify="flex-start" align="flex-end">
                        <RHWContainer
                          rain={day && day.precipitationProbabilityDay}
                          humidity={day && day.humidityNoon}
                          wind={day && day.windSpeed}
                          windDirection={day && day.windDirection}
                        />
                      </RowContainer>
                    </DayBody>
                  </DayContainer>
                );
              })}
          </RowContainer>
        </MainContainer>
      </ColumnContainer>
      {(forecastLoading || loading || farmLoading) && (
        <CircularProgress style={{ position: "absolute", bottom: "2%", right: "2%" }} color="primary" />
      )}
    </ColumnContainer>
  );
};

const MainContainer = styled(ColumnContainer)`
  background-color: #1d1d1d;
  border: #616161 1px solid;
  height: 100%;
  width: 100%;
  padding: 1%;
  justify-content: flex-start;
`;

const BackImage = styled.img`
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  filter: grayscale(100%);
`;

const TodayContainer = styled(RowContainer)`
  width: 100%;
  height: 48%;
  border: #616161 1px solid;
  background: linear-gradient(180deg, #000000cc 0%, #0f1110cc 50%, #000000cc 100%);
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2%;
  margin-bottom: 10px;
  font-size: 18px;
`;

const DayContainer = styled(ColumnContainer)`
  flex: 1;
  height: 100%;
  justify-content: flex-start;
  margin-right: 20px;
  border: 1px #454545 solid;
  :last-child {
    margin: 0;
  }
`;

const DayLabel = styled.label`
  width: 70px;
  padding: 2px;
  background-color: #4babf0;
  color: white;
  text-align: center;
  border-radius: 15px;
  font-size: 15px;
`;

const TempLabel = styled.label`
  font-size: 35px;
  font-weight: 600;
  color: #15a357;

  > span {
    font-weight: normal !important;
  }
`;

const DayHeader = styled(RowContainer)`
  background: linear-gradient(180deg, #2c3731 0%, #000000 100%);
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;

  span {
    font-size: 35px;
    font-weight: 600;
    color: #15a357;
  }
`;

const DayBody = styled(ColumnContainer)`
  background-color: #282828;
  color: white;
  width: 100%;
  height: calc(100% - 60px);
  justify-content: center;
  font-size: 18px;
`;
