import { ColumnContainer, PrimaryButton, RowContainer } from "@agxio/ui-kit";
import { useMutation } from "@apollo/react-hooks";
import React from "react";
import { CentaurGraphql } from "../../../../graphql/Centaur/configuration";
import { SuccessAlert } from "../../../../utils/MercurySwal";

export default () => {
  const [updateAhdbData] = useMutation(CentaurGraphql.UpdateAhdbData, {
    onCompleted: data => {
      if (data.updateAhdbData) {
        SuccessAlert("AHDB data is now being updated. This might take a while.", 10000);
      }
    },
  });

  return (
    <ColumnContainer width="100%" height="100%" align="flex-start" justify="space-between">
      <RowContainer width="15%" height="100%" align="flex-start" justify="space-between">
        <PrimaryButton type="button" onClick={() => updateAhdbData()}>
          Update AHDB Data
        </PrimaryButton>
      </RowContainer>
    </ColumnContainer>
  );
};
