import React, { createContext, useReducer, ReactNode } from "react";
import Reducer from "./reducer";
import { ContextType, Types } from "./types";

const initialState = {
  openDrawer: false,
};

export const Context = createContext({} as ContextType);

export const ProjectStoreProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const setOpenDrawer = async (open: boolean) => {
    dispatch({
      type: Types.SET_OPEN_DRAWER,
      payload: {
        open,
      },
    });
  };

  return (
    <Context.Provider
      value={{
        states: state,
        actions: {
          setOpenDrawer,
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
