import { ColumnContainer } from "@agxio/ui-kit";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { SubTabContainer } from "../../../../sharedComponents/SubTabContainer";
import { routes } from "./routes";

export default () => {
  return (
    <ColumnContainer width="100%" height="100%" align="flex-start" justify="space-between">
      <SubTabContainer routes={routes} baseUrl="/centaur/dashboards" />
      <Body>
        <Switch>
          {routes.map((item, key) => (
            <Route path={`/centaur/dashboards${item.path}`} component={item.component} title={item.title} />
          ))}
          <Route
            exact
            path="/centaur/dashboards"
            render={() => <Redirect from="/centaur/dashboards" to="/centaur/dashboards/bycropclassandtype" />}
          />
        </Switch>
      </Body>
    </ColumnContainer>
  );
};

const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;
