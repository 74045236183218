import React from "react";
import { Paper, Popper, ClickAwayListener, Fade } from "@material-ui/core";
import styled, { css } from "styled-components";
import logo from "../Assets/logo.png";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Auth } from "../utils/Auth";
import { SvgIcon, ProfileBox, RowContainer } from "@agxio/ui-kit";
import { config } from "../utils/config";
import { UserProfile } from "../types";

interface HeaderProps extends RouteComponentProps {
  actions: {
    saveProject: () => void;
    saveAsProject: () => void;
  };
  moduleName: string;
}

interface HeaderState {
  open: boolean;
  anchorEl: HTMLElement | null;
  userInfo: UserProfile;
}

class HeaderComponent extends React.Component<HeaderProps, HeaderState> {
  state = {
    anchorEl: null,
    open: false,
    userInfo: Auth.getUserProfile(),
  };

  handleProfileClicked = (event: any) => {
    const { currentTarget } = event;
    this.setState({
      anchorEl: currentTarget,
      open: !this.state.open,
    });
  };

  handleClickAway = () => {
    this.setState({
      open: false,
    });
  };

  handleLogout = async () => {
    Auth.logout();
    this.props.history.push("/");
    window.location.reload();
  };

  render = () => {
    const id = this.state.open ? "profile-popper" : undefined;

    return (
      <Container>
        <RowContainer>
          <LogoContainer>
            <Link to="/">
              <Logo alt="Agxio logo" src={logo} />
            </Link>
          </LogoContainer>
        </RowContainer>

        <IconsContainer>
          <RowContainer>
            <Title>
              Centuar, <span>Command Centaur</span>
            </Title>
            <SvgIcon name="PrinterTag" onClick={window.print} />
            <SvgIcon aria-describedby={id} name="SettingsIcon" onClick={this.handleProfileClicked} />
            <Popper
              id={id}
              style={{ zIndex: 2 }}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              transition
              placement={"bottom-end"}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={this.handleClickAway}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <ProfileBox
                        appTitle={`${process.env.REACT_APP_TITLE}`}
                        versionNo={config.version}
                        userInfo={this.state.userInfo}
                        handleLogout={this.handleLogout}
                      />
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </RowContainer>
        </IconsContainer>
      </Container>
    );
  };
}

export const Header = withRouter(HeaderComponent);

const FlexItem = css``;

const LogoContainer = styled.div`
  flex: 1;
  padding-right: 5%;
`;

const Logo = styled.img`
  ${FlexItem};
`;

const Title = styled.div`
  color: white;
  font-size: 16px;
  margin-right: 10px;
  span {
    color: #80c29d;
    font-size: 14px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2vw;
  padding-right: 1%;
  justify-content: space-between;
  align-items: center;
  height: 85px;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  .fa {
    font-size: 20px;
  }
  .fa-robot {
    font-size: 19px;
  }
  .fa-save {
    font-size: 21px;
  }
  ${FlexItem} h3 {
    color: #80c29d;
    width: fit-content;
    align-self: flex-end;
    margin-right: 13px;
  }

  .icons {
    display: flex;
    align-items: center;
  }
`;
