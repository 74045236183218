import React from "react";
import { ColumnContainer, RowContainer, Wrapper } from "@agxio/ui-kit";
import styled from "styled-components";
import SupportForm from "@agxio/module-support";
import supportBg from "../../../Assets/support_bg.png";
import { Auth } from "../../../utils/Auth";
import { config } from "../../../utils/config";

export const Support = () => {
  const {
    companyName,
    profile: { fullName, email },
  } = Auth.getUserProfile();
  const [firstName, ...lastName] = fullName.split(" ");
  return (
    <RowContainer
      background="white"
      width="100%"
      height="100%"
      overflow="auto"
      justify="space-between"
      align="flex-start"
      padding="1% 4%;"
    >
      <ColumnContainer width="46%" height="100%" justify="flex-start">
        <img src={supportBg} height="auto" width="100%" alt="Support Background" />
        <SupportInfoContainer>
          <p>Agxio Limited</p>
          <p>
            <span>Aberystwyth Innovation & Enterprise Centre </span>
            <span>Plas Goggerdan </span>
            <span>Aberystwyth SY23 3EE</span>
          </p>

          <p>
            <span>Bradfield Centre </span>
            <span>Cambridge Science Park </span>
            <span>Cambridge CB4 0GA</span>
          </p>
          <p>
            Email: <a href="mailto:support@Agxio.com">support@Agxio.com</a>
          </p>
        </SupportInfoContainer>
      </ColumnContainer>
      <Wrapper width="50%" height="100%">
        <SupportForm
          apiKey={Auth.getToken()}
          companyName={companyName}
          fromSource={0}
          firstName={firstName}
          lastName={lastName.join(" ")}
          email={email}
          supportUrl={`${config.commonService.baseURL}/api/sendSupportEmail`}
        />
      </Wrapper>
    </RowContainer>
  );
};

const SupportInfoContainer = styled(RowContainer)`
  width: 100%;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #232323;
  font-size: 20px;
  padding: 2px 16px;
  margin-bottom: 32px;
  a {
    color: #307bc6;
    text-decoration: underline;
  }
  p {
    margin: 5px;
  }
  p span {
    display: block;
  }
`;
