import { ColumnContainer, DropDown, PrimaryButton, RowContainer } from "@agxio/ui-kit";
import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../../../../../Assets/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../../../Assets/arrowUp.svg";
import { PredictedMap } from "../../components/PredictedMap";
import { UKMap } from "../../components/UKMap";

const ByCropTypeAndClass = () => {
  return (
    <MainContainer>
      <RowContainer width="100%" justify="flex-start" padding="10px 2.5%" style={{ backgroundColor: "#2E2E2E" }}>
        <DropDown label="F/T:" onSelect={selected => {}} selected={""} options={[]} style={{ width: "26%" }} />
        <DropDown
          label="Group:"
          onSelect={selected => {}}
          selected={""}
          options={[]}
          style={{ width: "23%", marginLeft: "1.5%" }}
        />
      </RowContainer>
      <RowContainer width="95%" height="100%" padding="1% 0 0 0">
        <ColumnContainer flex="1.07" justify="space-between" height="100%">
          <Box hasBorder flex="1" margin="0 0 1% 0">
            <RowRap>
              <ColoredBox color={"green"}>Soil PH</ColoredBox>
              <ColoredBox color={"green"}>Soil Moisture</ColoredBox>
            </RowRap>
            <RowRap>
              <ColoredBox color={"green"}>Soil PH</ColoredBox>
              <ColoredBox color={"green"}>Soil Moisture</ColoredBox>
            </RowRap>
          </Box>
          <Box hasBorder flex="1" margin="1% 0">
            <RowRap>
              <ColoredBox color={"red"}>
                Smart Group Rating:
                <div style={{ fontSize: 20 }}>85</div>
              </ColoredBox>
              <ColumnContainer height="100%" width="150px">
                <div style={{ fontSize: 22 }}>83</div>
                <ArrowUp width="50" height="50" />
              </ColumnContainer>
            </RowRap>
          </Box>
          <Box hasBorder flex="1" margin="1% 0 0 0">
            <RowRap>
              <ColoredBox color={"green"}>Sensor Network</ColoredBox>
              <ColoredBox color={"green"}>Data Universe</ColoredBox>
            </RowRap>
            <RowRap>
              <ColoredBox color={"green"}>Machine Learning</ColoredBox>
              <ColoredBox color={"red"}>Parameters</ColoredBox>
            </RowRap>
          </Box>
        </ColumnContainer>
        <ColumnContainer flex="2" padding="0 5px" height="100%">
          <Box height="100%" style={{ backgroundColor: "unset" }}>
            <ColumnContainer height="100%" width="100%" justify="space-between">
              <RowContainer width="100%" justify="space-between">
                <CustomButton margin="0 5px 0 0">Planning</CustomButton>
                <CustomButton margin="0 5px">Deploy</CustomButton>
                <CustomButton margin="0 5px">Monitor</CustomButton>
                <CustomButton margin="0 0 0 5px">Finalise</CustomButton>
              </RowContainer>
              <Box style={{ backgroundColor: "unset", paddingTop: "5px", height: "100%" }} noPadding>
                Predicted Total Yield Per Farm
                <div style={{ width: "100%", height: "100%" }}>
                  <PredictedMap color={["#EB7605", "#F6C117"]} />
                </div>
              </Box>
              <RowContainer width="100%" justify="space-around">
                <PredictedBox>
                  <span>Predicted Yield:</span>
                  <span style={{ fontSize: 18 }}>150t</span>
                  <span style={{ fontSize: 12, color: "red" }}>150t/ha</span>
                </PredictedBox>
                <PredictedBox>
                  <span>Predicted Return:</span>
                  <span style={{ fontSize: 18 }}>£229k</span>
                  <span style={{ fontSize: 12, color: "red" }}>£9k/ha</span>
                </PredictedBox>
                <PredictedBox>
                  <span>Predicted Market:</span>
                  <span style={{ fontSize: 18 }}>Nov.</span>
                  <span style={{ fontSize: 12, color: "red" }}>22nd</span>
                </PredictedBox>
              </RowContainer>
            </ColumnContainer>
          </Box>
        </ColumnContainer>
        <ColumnContainer flex="1" justify="space-between" height="100%">
          <Box hasBorder flex="1" margin="0 0 1% 0">
            <UKMap />
          </Box>
          <Box hasBorder flex="1" margin="1% 0">
            <RowRap>
              <ColoredBox color={"green"}>
                Group Sustainability Rating:
                <div style={{ fontSize: 20 }}>105</div>
              </ColoredBox>
              <ColumnContainer height="100%" width="150px">
                <div style={{ fontSize: 22 }}>108</div>
                <ArrowDown width="50" height="50" />
              </ColumnContainer>
            </RowRap>
          </Box>
          <Box hasBorder flex="1" margin="1% 0 0 0">
            <RowRap>
              <ColoredBox color={"green"}>Yield</ColoredBox>
              <ColoredBox color={"green"}>Price</ColoredBox>
            </RowRap>
            <RowRap>
              <ColoredBox color={"green"}>Disease</ColoredBox>
              <ColoredBox color={"green"}>Weather</ColoredBox>
            </RowRap>
          </Box>
        </ColumnContainer>
      </RowContainer>
    </MainContainer>
  );
};

const MainContainer = styled(ColumnContainer)`
  width: 100%;
  height: 100%;
`;

interface ColumnContainerInterface {
  hasBorder?: boolean;
  noPadding?: boolean;
}
const Box = styled(ColumnContainer)<ColumnContainerInterface>`
  background-color: black;
  border: ${({ hasBorder }) => (hasBorder ? "solid 1px #616161" : "none")};
  padding: ${({ noPadding }) => (noPadding ? "none" : "1%")};
  width: 100%;
`;

const RowRap = styled(RowContainer)`
  width: 100%;
  height: 100%;
  justify-content: space-around;
  margin: 1%;
`;

const ColoredBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props: any) =>
    props.color === "green"
      ? "linear-gradient(180deg, #19A355, #056E34)"
      : "linear-gradient(180deg, #dd6969, #da4141)"};
  font-size: 16px;
  color: white;
  text-align: center;
  margin: 1%;
  padding: 10px;
  flex: 1;
  height: 100%;
`;

const CustomButton = styled(PrimaryButton)`
  background-color: #385622;
  color: white;
  border-radius: 0;
  flex: 1;
`;

const PredictedBox = styled(ColumnContainer)`
  flex: 1;
  background-color: black;
  border: solid 1px #616161;
  padding: 1%;
  margin: 1%;
  height: 100%;
`;

export default ByCropTypeAndClass;
