import {
  CircularLoading,
  ColumnContainer,
  DropDown,
  ErrorAlert,
  FormContainer,
  Header,
  ListContainer,
  ListTable,
  RowContainer,
  SuccessAlert,
} from "@agxio/ui-kit";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import React, { useEffect, useReducer } from "react";
import { BaselineStrategyGraphql } from "../../../../graphql/Centaur/baselineStrategy";
import { DropDownsGraphql } from "../../../../graphql/dropDowns";
import { pleaseSelect } from "../../../../utils/dropdowns";
import { ShowDeleteModal } from "../../../../utils/MercurySwal";
import { AddBaselineStrategy } from "./AddBaselineStrategy";

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
    background: {
      paper: "#fff",
    },
  },
  typography: {},
});

interface State {
  selectedFarmGroup: number;
  selectedFarm: number;
  selectedField: number;
  selectedRow: any;
}

const initialState: State = {
  selectedFarmGroup: -1,
  selectedFarm: -1,
  selectedField: -1,
  selectedRow: undefined,
};

const reducer = (state: State, action: any) => {
  switch (action.type) {
    case "farmGroup":
      return {
        ...state,
        selectedFarmGroup: action.payload,
      };
    case "farm":
      return {
        ...state,
        selectedFarm: action.payload,
      };
    case "field":
      return {
        ...state,
        selectedField: action.payload,
      };
    case "selectedRow":
      return {
        ...state,
        selectedRow: action.payload,
      };
    default:
      throw new Error("Invalid");
  }
};

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: farmGroups, loading: farmGroupLoading } = useQuery(DropDownsGraphql.GetFarmGroups);
  const { data: dropDowns, loading: dropDownLoading } = useQuery(BaselineStrategyGraphql.GetBaselineStrategyDropDowns);
  const [getFarms, { data: farms, loading: farmLoading }] = useLazyQuery(DropDownsGraphql.GetFarms);
  const [getFields, { data: fields, loading: fieldLoading }] = useLazyQuery(DropDownsGraphql.GetFields);
  const [getBaselineStretgies, { data: baselineStrategies, loading: bsLoading, refetch }] = useLazyQuery(
    BaselineStrategyGraphql.GetBaselineStrategies,
    {
      fetchPolicy: "no-cache",
    },
  );
  const [saveBs] = useMutation(BaselineStrategyGraphql.SaveBaselineStrategy);
  const [deleteBs] = useMutation(BaselineStrategyGraphql.DeleteBaselineStrategy);

  useEffect(() => {
    getFarms({ variables: { farmGroupId: state.selectedFarmGroup } });
  }, [state.selectedFarmGroup, getFarms]);

  useEffect(() => {
    getFields({ variables: { farmId: state.selectedFarm } });
  }, [state.selectedFarm, getFields]);

  useEffect(() => {
    getBaselineStretgies({ variables: { fieldID: state.selectedField } });
  }, [state.selectedField, getBaselineStretgies]);

  return (
    <MuiThemeProvider theme={theme}>
      <ColumnContainer width="100%" height="100%" padding="0" justify="flex-start">
        <RowContainer width="100%" justify="flex-start" padding="10px 2.5%" style={{ backgroundColor: "#2E2E2E" }}>
          <DropDown
            label="Farm Group:"
            onSelect={selected => {
              dispatch({ type: "farmGroup", payload: selected });
              dispatch({ type: "farm", payload: -1 });
              dispatch({ type: "field", payload: -1 });
              dispatch({ type: "selectedRow", payload: undefined });
            }}
            selected={state.selectedFarmGroup}
            options={
              farmGroups
                ? [
                    pleaseSelect,
                    ...farmGroups.getFarmGroups.map((item: any) => ({
                      title: item.FarmGroupName,
                      value: item.FarmGroupID,
                    })),
                  ]
                : []
            }
            style={{ width: "23%" }}
          />
          <DropDown
            label="Farm:"
            onSelect={selected => {
              dispatch({ type: "farm", payload: selected });
              dispatch({ type: "field", payload: -1 });
              dispatch({ type: "selectedRow", payload: undefined });
            }}
            selected={state.selectedFarm}
            options={
              farms
                ? [
                    pleaseSelect,
                    ...farms.getFarms.map((item: any) => ({
                      title: item.FarmName,
                      value: item.FarmID,
                    })),
                  ]
                : []
            }
            style={{ width: "23%", marginLeft: "1.5%" }}
          />
          <DropDown
            label="Field:"
            onSelect={selected => {
              dispatch({ type: "field", payload: selected });
              dispatch({ type: "selectedRow", payload: undefined });
            }}
            selected={state.selectedField}
            options={
              fields
                ? [
                    pleaseSelect,
                    ...fields.getFields.map((item: any) => ({
                      title: item.FieldName,
                      value: item.FieldID,
                    })),
                  ]
                : []
            }
            style={{ width: "23%", marginLeft: "1.5%" }}
          />
        </RowContainer>
        <RowContainer width="100%" height="92%" justify="space-between" padding="10px 2.5%">
          <ColumnContainer flex="1" margin="0 1% 0 0" height="100%" justify="space-between">
            <FormContainer width="100%" height="100%">
              <Header>
                <div>Baseline Strategy</div>
              </Header>
              <AddBaselineStrategy
                baselineStrategy={state.selectedRow}
                dropdowns={dropDowns && dropDowns.getBaselineStrategyDropDowns}
                onNew={() => dispatch({ type: "selectedRow", payload: undefined })}
                fieldID={state.selectedField}
                saveBaselineStrategy={async input => {
                  try {
                    await saveBs({ variables: { input } });
                    SuccessAlert("Baseline Strategy Has Been Saved Successfully");
                    dispatch({ type: "selectedRow", payload: undefined });
                    refetch();
                    return true;
                  } catch (error) {
                    ErrorAlert(error.graphQLErrors[0].message);
                    return false;
                  }
                }}
                deleteBaselineSt={async () => {
                  if ((await ShowDeleteModal("This item")).value) {
                    deleteBs({ variables: { baselineStrategyID: state.selectedRow.baselineStrategyID } });
                    dispatch({ type: "selectedRow", payload: undefined });
                    refetch();
                  }
                }}
              />
            </FormContainer>
          </ColumnContainer>
          <ListContainer flex="2" height="100%" justify="space-between">
            <Header>
              <div>Field History</div>
            </Header>
            <ListTable
              data={baselineStrategies ? baselineStrategies.getBaselineStrategies : []}
              idColumn="baselineStrategyID"
              onSelectItem={item => dispatch({ type: "selectedRow", payload: item })}
              selectedRow={state.selectedRow}
              headers={[
                {
                  title: "Crop Type",
                  value: "cropType",
                  width: "150px",
                },
                {
                  title: "Sub-Variety",
                  value: "subVariant",
                  width: "150px",
                },
                {
                  title: "Crop Variety",
                  value: "variety",
                  width: "150px",
                },
                {
                  title: "Trial",
                  value: "trial",
                  width: "150px",
                },
                {
                  title: "Baseline Date",
                  value: "baselineDate",
                  width: "150px",
                  date: true,
                },
              ]}
            />
          </ListContainer>
        </RowContainer>
        {(bsLoading || dropDownLoading || farmGroupLoading || farmLoading || fieldLoading) && (
          <CircularLoading color="secondary" />
        )}
      </ColumnContainer>
    </MuiThemeProvider>
  );
};
