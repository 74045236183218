import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import HomeItem from "../../components/HomeItem";
import { RowContainer, ColumnContainer } from "@agxio/ui-kit";

export default () => {
  return (
    <MainContainer padding="0 2% 0 2%">
      <Helmet>
        <title>Centaur</title>
      </Helmet>
      <RowContainer width="100%" height="100%" justify="space-evenly" padding="0 64px 0 0">
        <RowContainer flex="1" height="80%" justify="flex-start" align="flex-start" padding="10%">
          <div>
            <Heading>Centaur</Heading>
            <SubText>Command Centaur</SubText>
          </div>
        </RowContainer>
        <ButtonContainer flex="1.4" width="100%" height="fit-content" justify="space-between" align="center">
          <RowContainer width="100%" justify="flex-start">
            <HomeItem navigateTo="/centaur/dashboards" name={"Dashboards"} title={"Dashboards"} />
            <HomeItem navigateTo="/centaur/weather" name={"Weather"} title={"Weather"} />
          </RowContainer>
        </ButtonContainer>
      </RowContainer>
    </MainContainer>
  );
};

interface MainContainerProps {
  height?: string;
  padding?: string;
}

export const MainContainer = styled.div`
  width: 100%;
  height: ${(props: MainContainerProps) => (props.height ? props.height : "100%")};
  display: flex;
  flex-direction: column;
  ${(props: MainContainerProps) => props.padding && { padding: props.padding }}
`;

const ButtonContainer = styled(ColumnContainer)`
  display: flex;
  justify-content: space-between;
`;

interface CustomButtonsProps {
  disabled?: boolean;
}

const SubText = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: 450;
  font-size: 30px;
  line-height: 120%;
  color: #0f9349;
  letter-spacing: -0.01em;
`;
const Heading = styled.h1`
  font-weight: 600;
  font-size: 64px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 16px;
  font-style: normal;
  letter-spacing: -0.01em;
`;
