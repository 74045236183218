import React, { useState } from "react";
import { createMuiTheme, MuiThemeProvider, TextField } from "@material-ui/core";
import "./Login.css";
import loginLogo from "../Assets/logo_green.png";
import loginImage from "../Assets/login_image.png";
import { Auth } from "../utils/Auth";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { UserGraphQL } from "../graphql";
import { ErrorAlert } from "@agxio/ui-kit";

interface Props {}

const loginTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#056e34",
    },
    secondary: {
      main: "#000",
    },
    background: {
      paper: "#FFF",
    },
  },
  typography: {},
});

export const Login = (props: Props) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errUsername, setErrUsername] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [login, { loading }] = useMutation(UserGraphQL.UserLogin, {
    onCompleted: data => Auth.storeAuthInfo(data.login),
    onError: error => ErrorAlert(error.graphQLErrors[0].message),
  });

  return (
    <MuiThemeProvider theme={loginTheme}>
      <Main>
        <LoginArea>
          <Form
            onSubmit={async e => {
              e.preventDefault();
              setErrUsername(username.length < 2 ? "Username Is Required" : "");
              setErrPassword(password.length < 2 ? "Password Is Required" : "");
              if (username.length < 2 || password.length < 2) return;
              login({
                variables: {
                  username,
                  password,
                },
              });
            }}
          >
            <FormGroup>
              <LogoContainer>
                <img src={loginLogo} alt="Agxio" />
              </LogoContainer>
              <TextField
                label={"USERNAME"}
                fullWidth
                error={errUsername.length > 0}
                helperText={errUsername}
                onChange={event => setUserName(event.target.value)}
                value={username}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label={"PASSWORD"}
                type="password"
                fullWidth
                error={errPassword.length > 0}
                helperText={errPassword}
                onChange={event => setPassword(event.target.value)}
                value={password}
              />
            </FormGroup>
            <LoginButton type="submit" disabled={loading}>
              LOGIN
            </LoginButton>
          </Form>
          <ImagesContainer>
            <TextContainer>
              <h1>Centaur</h1>
              <h4>Centaur Command</h4>
            </TextContainer>
          </ImagesContainer>
        </LoginArea>
        <div className="agxio-footer">© {new Date().getFullYear()} Agxio Limited</div>
      </Main>
    </MuiThemeProvider>
  );
};

const Main = styled.div`
  width: 100%;
  height: 100vh;
`;

const LoginArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 540px;
  background: #ffffff;
  border-radius: 40px;
  padding: 32px;
  display: flex;
`;

const Form = styled.form`
  margin-right: 64px;
  margin-left: 32px;
  flex: 1;
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
  padding: 5px;
  font-weight: bold;
`;

const ImagesContainer = styled.div`
  flex: 1.1;
  position: relative;
  background: #000;
  background-position: center;
  background-image: url(${loginImage});
  background-repeat: no-repeat;
  background-size: contain;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
`;

const TextContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 78px;
    font-weight: 400;
    margin: 0;
    color: #ffffff;
    text-align: center;
    letter-spacing: -0.01em;
    margin-bottom: 32px;
  }

  h4 {
    font-weight: 400;
    font-size: 32px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.01em;
    margin: 0;
    color: #0f9349;
  }
`;

const LogoContainer = styled.div`
  margin: 32px 0;
  position: relative;
  margin-bottom: 30px;
  text-align: left;
`;
const LoginButton = styled.button`
  background: transparent;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  text-align: center;
  height: 52px;
  margin-top: 32px;
  color: #fff;
  background: linear-gradient(180deg, #19a355 0%, #056e34 100%);
  border: 2px solid #076b32;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px rgba(183, 240, 208, 0.7);
  border-radius: 10px;
  :hover {
    background: linear-gradient(180deg, #27d472 0%, #056e34 100%);
    cursor: pointer;
  }
`;
