import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../../../utils/colors";
export const BoxItem = ({ label, value }: { label: string; value: number | null | undefined }) => {
  const color = getBoxItemreturn(value);
  return (
    <Container color={color}>
      <p>{label}</p>
      <strong>
        {(value && (value.toString() === "-1" ? "-1" : parseFloat(value.toString()).toFixed(2))) || "N/A"}
      </strong>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  strong {
    width: 10%;
    height: 80%;
    background: ${(props: { color: string }) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const getBoxItemreturn = (value: number | null | undefined) => {
  if (value === null || value === undefined || value === -1) return COLORS.GRAY;
  if (value >= 7) return COLORS.RED;
  if (value >= 5 && value < 7) return COLORS.ORANGE;
  return COLORS.GREEN;
};
