import React from "react";
import routes from "./routes";
import { Route, RouteProps, Redirect, Switch } from "react-router-dom";
import styled from "styled-components";
import { SubTabContainer } from "../../sharedComponents/SubTabContainer";
import NotAuthorized from "../../commonPages/NotAuthorized";

interface WeatherProps extends RouteProps {
  baseUrl: string;
}

export class Weather extends React.Component<WeatherProps> {
  render() {
    return (
      <MainContainer>
        <SubTabContainer routes={routes} baseUrl={this.props.baseUrl} />
        <Body>
          <Switch>
            {routes.map((route, i) => (
              <Route
                key={i}
                path={`${this.props.baseUrl}${route.path}`}
                component={route.component}
                title={route.title}
              />
            ))}
            <Route exact path="/weather" render={() => <Redirect from="/weather" to="/weather/current" />} />
            <Route
              exact
              path={this.props.baseUrl}
              render={() => <Redirect from={this.props.baseUrl} to={`${this.props.baseUrl}/current`} />}
            />
            <Route component={NotAuthorized} />
          </Switch>
        </Body>
      </MainContainer>
    );
  }
}

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  padding: 0;
`;
