import { gql } from "apollo-boost";

const UpdateAhdbData = gql`
  mutation updateAhdbData {
    updateAhdbData
  }
`;

export const CentaurGraphql = {
  UpdateAhdbData,
};
