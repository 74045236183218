import React from "react";
import { Header } from "./Header";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

export const Layout = (props: Props) => {
  return (
    <MainContainer>
      <Header
        moduleName={"centaur"}
        actions={{
          saveProject: () => true,
          saveAsProject: () => true,
        }}
      />
      <ChildrenContainer>
        <Children>{props.children}</Children>
      </ChildrenContainer>
      <FooterCopyRight>&copy; {new Date().getFullYear()} Agxio Limited </FooterCopyRight>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const footerHeight = "50px";

const ChildrenContainer = styled.div`
  height: calc(100% - 85px - ${footerHeight});
`;

const Children = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const FooterCopyRight = styled.div`
  position: fixed;
  background-color: black;
  width: 100%;
  height: ${footerHeight};
  display: flex;
  align-items: center;
  padding: 0 2.5%;
  bottom: 0px;
  color: white;
  font-size: 14px;
  font-weight: 500;
`;
