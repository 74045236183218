import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { RowContainer } from "@agxio/ui-kit";

interface Props {
  routes: any;
  baseUrl: string;
}

export const TabContainer = (props: Props) => {
  const location = useLocation();
  let current = location.pathname.substring(location.pathname.lastIndexOf("/"));
  if (location.pathname.includes("/dashboards")) {
    current = "/dashboards";
  }
  return (
    <Container>
      <TabBar justify="flex-start">
        <TabTitleItems>
          {props.routes.map((item: any, key: number) => (
            <TabTitleItem key={key} isActive={current === item.path}>
              <Link style={{ width: "100%" }} to={`${props.baseUrl}${item.path}`}>
                {item.title}
              </Link>
            </TabTitleItem>
          ))}
        </TabTitleItems>
      </TabBar>
    </Container>
  );
};

const Container = styled.div`
  background: linear-gradient(180deg, #19a355 0%, #056e34 100%), #000000;
`;

const Common = css`
  justify-content: flex-start;
  height: 45px;
`;
const TabBar = styled(RowContainer)`
  ${Common}
`;

const TabTitleItems = styled.ul`
  height: 90%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  font-weight: normal;
  align-items: center;
  padding-left: 0;
  a {
    box-sizing: content-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;

    :after {
      content: "";
      display: none;
      background-color: #fff;
      position: absolute;
      bottom: -10px;
      width: 0%;
      left: 0;
      right: 0;
      height: 2px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const TabTitleItem = styled.li`
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 140px;
  padding-left: 0;
  padding-right: 0;
  flex-grow: 1;
  flex-basis: auto;
  text-align: center;
  color: white;
  :not(:first-child) {
    border-left: 1px solid #4fa775;
  }
  height: 60%;

  :hover {
    cursor: pointer;
  }
  :hover > div {
    display: block;
    cursor: pointer;
  }
  :hover a:after {
    width: 70%;
  }

  a {
    font-weight: ${(props: ListItemProps) => (props.isActive ? "400" : "100")};
  }

  a:after {
    transition: width 0.2s;
    display: block;
    width: ${(props: ListItemProps) => (props.isActive ? "70%" : "0%")};
  }
`;

TabTitleItems.defaultProps = {
  color: "#4fa775",
};

interface ListItemProps {
  color?: string;
  isActive?: boolean;
}
