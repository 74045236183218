import React, { useEffect } from "react";
import { anychartRemoveContextItems } from "../../../../utils/Anychart";

interface Props {
  days: number[];
}

let chart: anychart.charts.Cartesian | null = null;

const dailyForecastChart = (days: any[]) => {
  if (chart) {
    chart.dispose();
    chart = null;
  }
  chart = anychart.stepLine();
  chart.stepLine([
    { x: "Today", value: days[0] },
    { x: "+1 Day", value: days[1] },
    { x: "+2 Days", value: days[2] },
    { x: "+3 Days", value: days[3] },
    { x: "+4 Days", value: days[4] },
  ]);

  chart.title().enabled(true).text("5 Day Horizon").fontColor("white").fontSize("14px").hAlign("center");
  chart.width("100%");
  chart.tooltip(false);
  chart.contextMenu().itemsFormatter(anychartRemoveContextItems);
  chart.background("#0000");
  chart.credits(false);
  chart.container("linechart");
  chart.draw();
};

export const DailyTempLineChart = (props: Props) => {
  useEffect(() => dailyForecastChart(props.days), [props]);
  return <div id="linechart" style={{ width: "100%", height: "100%" }} />;
};
