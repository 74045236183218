import React, { useEffect } from "react";
import { anychartRemoveContextItems } from "../../../utils/Anychart";

interface Props {
  value: number;
}

let simpleGauge: anychart.charts.LinearGauge | null = null;

const drawLinearGauge = (value: number) => {
  var gauge = anychart.gauges.linear();
  gauge.data([value]).padding(10, 0, 30, 0);

  var scale = gauge.scale();
  scale.minimum(-20).maximum(100);

  var axis = gauge.axis();
  axis.enabled(true);

  axis.labels().enabled(false);
  gauge.padding(20, 0, 10, 0);
  gauge.width("50%");
  gauge.tooltip(false);
  gauge.contextMenu().itemsFormatter(anychartRemoveContextItems);
  return gauge;
};

const getThermometer = (temp: number) => {
  if (simpleGauge) {
    simpleGauge.dispose();
    simpleGauge = null;
  }
  simpleGauge = drawLinearGauge(temp);
  var simpleThermometer = simpleGauge.thermometer(0);

  // Set simple thermometer settings
  simpleThermometer.name("Thermometer").id("0").fill("#64b5f6").stroke("#64b5f6");
  simpleThermometer.width("30%");

  simpleGauge.credits(false);
  simpleGauge.background("#0000");
  simpleGauge.container("tempgauge");
  simpleGauge.height("100%");
  simpleGauge.draw();
};

export const TempGauge = (props: Props) => {
  useEffect(() => getThermometer(props.value), [props]);
  return <div id="tempgauge" style={{ width: "40px", height: "100%" }} />;
};
