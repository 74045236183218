import * as yup from "yup";

export const BaselineStrategySchema = yup.object().shape({
  cropTypeID: yup.number().moreThan(0).required().label("Crop Type"),
  subVariantID: yup.number().moreThan(0).required().label("Sub-Variety"),
  varietyID: yup.number().moreThan(0).required().label("Crop Variety"),
  trialID: yup.number().moreThan(0).required().label("Trial Goal"),
  emergenceModelID: yup.number().moreThan(0).required().label("Emergence Model"),
  tillageModelID: yup.number().moreThan(0).required().label("tillage Model"),
  harvestModelID: yup.number().moreThan(0).required().label("Harvest Model"),
  fertilisationModelID: yup.number().moreThan(0).required().label("Fertilisation"),
  flightMapModelID: yup.number().moreThan(0).required().label("Flight Map Model"),
});
