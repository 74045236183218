import Current from "../tabs/Current";
import Relative from "../tabs/Relative";
import Textual from "../tabs/Textual";
import Historical from "../tabs/Historical";

const routes = [
  {
    title: "Current",
    path: "/current",
    component: Current,
    color: "#4472C5",
  },
  {
    title: "Relative",
    path: "/relative",
    exact: true,
    component: Relative,
    color: "#548235",
  },
  {
    title: "Textual",
    path: "/textual",
    exact: true,
    component: Textual,
    color: "#548235",
  },
  {
    title: "Historical",
    path: "/historical",
    exact: true,
    component: Historical,
    color: "#548235",
  },
];

export default routes;
