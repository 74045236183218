import ByCropTypeAndClass from "../sections/CropClassAndType";

export const routes = [
  {
    title: "By Crop Class and Type",
    path: "/bycropclassandtype",
    component: ByCropTypeAndClass,
  },
  {
    title: "By Farm",
    path: "/byfarm",
    component: ByCropTypeAndClass,
  },
  {
    title: "By Field",
    path: "/byfield",
    component: ByCropTypeAndClass,
  },
  {
    title: "Consolidated",
    path: "/consolidated",
    component: ByCropTypeAndClass,
  },
];
