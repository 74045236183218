const getSensorGroups = (data: any[], farm: number) => {
  try {
    const filtered = data.filter((item: any) => item.farmGroupId === farm && item.sensorGroup);
    const mapped = filtered
      .map((item: any) => ({
        title: item.sensorGroup,
        value: item.sensorGroupId,
      }))
      .sort((a, b) => (a.title > b.title ? 1 : -1));
    const filtered2 = mapped.filter((x, key) => mapped.findIndex(y => y.value === x.value) === key);
    return filtered2;
  } catch {
    return [];
  }
};

const getCompanyNames = (data: any[]) =>
  data
    ? data.map(x => ({
        title: x.Title,
        value: x.ID,
      }))
    : [];

const getFarmNames = (data: any[]) => {
  try {
    const filtered = data.filter((x, key) => data.findIndex(y => y.farmGroupId === x.farmGroupId) === key);
    const mapped = filtered
      .map((item: any) => ({
        title: item.farmGroupName,
        value: item.farmGroupId,
      }))
      .sort((a, b) => (a.title > b.title ? 1 : -1));
    return mapped;
  } catch {
    return [];
  }
};

const getSensors = (data: any[], farm: number) => {
  try {
    const filtered = data.filter((item: any) => item.farmGroupId === farm && item.sensorName);
    const mapped = filtered
      .map((item: any) => ({
        title: item.sensorName,
        value: item.sensorId,
      }))
      .sort((a, b) => (a.title > b.title ? 1 : -1));
    return mapped;
  } catch {
    return [];
  }
};

const getSensorTypes = (data: any[], sensorGroup: number) => {
  try {
    const filtered = data.filter((item: any) => item.sensorGroupId === sensorGroup && item.sensorType);
    const mapped = filtered
      .map((item: any) => ({
        title: item.sensorType,
        value: item.sensorTypeId,
      }))
      .sort((a, b) => (a.title > b.title ? 1 : -1));
    const filtered2 = mapped.filter((x, key) => mapped.findIndex(y => y.value === x.value) === key);
    return filtered2;
  } catch {
    return [];
  }
};

const getFarms = (data: any) => {
  if (!data) return [];
  return data.sensorNetworkValuesConsolidated
    .map((item: any) => ({
      title: item.farmName,
      value: item.farmName,
    }))
    .filter((x: any, key: number, mapped: any[]) => mapped.findIndex(y => y.value === x.value) === key)
    .sort((a: any, b: any) => (a.title > b.title ? 1 : -1));
};

const pleaseSelect = {
  title: "Please Select...",
  value: -1,
};

const getCropClassItems = (data: any) => {
  return [
    pleaseSelect,
    ...((data &&
      data.getCropClasses.map((item: any) => ({
        title: item.cropClassName,
        value: item.cropClassID,
      }))) ||
      []),
  ];
};
const getCropTypeItems = (data: any) => {
  return [
    pleaseSelect,
    ...((data &&
      data.getCropTypes.map((item: any) => ({
        title: item.name,
        value: item.cropTypeID,
      }))) ||
      []),
  ];
};
const getSubVarientItems = (data: any) => {
  return [
    pleaseSelect,
    ...((data &&
      data.getSubVariants.map((item: any) => ({
        title: item.subVariantName,
        value: item.subVariantID,
      }))) ||
      []),
  ];
};
const getVarietyItems = (data: any) => {
  return [
    pleaseSelect,
    ...((data &&
      data.getVarieties.map((item: any) => ({
        title: item.cropVarietyName,
        value: item.varietyID,
      }))) ||
      []),
  ];
};

const getYieldDropDown = {
  getCropClassItems,
  getCropTypeItems,
  getSubVarientItems,
  getVarietyItems,
};

export {
  getSensorGroups,
  getFarmNames,
  getSensors,
  getFarms,
  getSensorTypes,
  pleaseSelect,
  getCompanyNames,
  getYieldDropDown,
};
