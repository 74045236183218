import React from "react";
import { ColumnContainer, RowContainer } from "@agxio/ui-kit";
import styled from "styled-components";
import RainImage from "../../../Assets/icons/rain.svg";
import HumidityImage from "../../../Assets/icons/humidity.svg";
import WindImage from "../../../Assets/icons/wind.svg";

interface Props {
  rain: number;
  windDirection: string;
  wind: number;
  humidity: number;
}

export const RHWContainer = (props: Props) => {
  return (
    <Container>
      <ImageContainer>
        <Image src={RainImage} alt="rain" />
        {props.rain && <Text>{props.rain} %</Text>}
      </ImageContainer>
      <ImageContainer>
        <Image src={HumidityImage} alt="rain" />
        {props.humidity && <Text>{props.humidity} %</Text>}
      </ImageContainer>
      <ImageContainer>
        <Image src={WindImage} alt="wind" />
        {props.windDirection && (
          <Text>
            {props.windDirection} - {props.wind} MPH
          </Text>
        )}
      </ImageContainer>
    </Container>
  );
};

const Container = styled(RowContainer)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 60%;
  height: 75%;
  justify: flex-start;
  align: flex-end;
`;

const ImageContainer = styled(ColumnContainer)`
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 2% 0;
  border-right: 1px solid #3d3d3d;
  justify: flex-start;
  align: flex-end;

  :last-child {
    border: none;
  }
`;

const Text = styled.span`
  margin-top: 1%;
  text-align: center;
  font-size: 20px;
  justify: flex-start;
  align: flex-end;
`;
