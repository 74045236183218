import React, { useEffect } from "react";
import { anychartRemoveContextItems } from "../../../../utils/Anychart";

interface Props {
  temperatures: any[];
  rainfalls: any[];
}

var historicCharts: anychart.charts.Stock | null = null;

const createChart = (props: Props) => {
  var preloader = anychart.ui.preloader();
  preloader.render(document.getElementById("lefthistoricChart")!);
  preloader.visible(true);

  if (historicCharts) {
    historicCharts.dispose();
    historicCharts = null;
  }

  historicCharts = anychart.stock();
  var plot1 = historicCharts.plot(0);
  plot1
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  plot1.line().data(props.temperatures);
  plot1.legend().enabled(false);
  plot1.title().enabled(true).text("Temperatures").fontSize("2vmin");

  var plot2 = historicCharts.plot(1);
  plot2
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  plot2.line().data(props.rainfalls);
  plot2.legend().enabled(false);
  plot2.title().enabled(true).text("Rainfalls").fontSize("2vmin");

  historicCharts.height("100%");
  historicCharts.scroller().area(props.temperatures);
  historicCharts.scroller().area(props.rainfalls);
  historicCharts.background({ fill: "#212121" });
  historicCharts.tooltip(false);
  historicCharts.contextMenu().itemsFormatter(anychartRemoveContextItems);
  historicCharts.container("chartscontainer");
  historicCharts.credits(false);
  historicCharts.draw();

  preloader.visible(false);
};

export const Charts = (props: Props) => {
  useEffect(() => createChart(props), [props]);
  return <div id="chartscontainer" style={{ width: "100%", height: "100%" }} />;
};
