import React, { useEffect } from "react";
import { anychartRemoveContextItems } from "../../../../../utils/Anychart";
import UkData from "./ukData.json";

let map: any;

const getMap = () => {
  if (map !== null && map !== undefined) map.dispose();
  map = null;
  map = anychart.map();

  map
    .credits()
    .enabled(true)
    .text(
      "Data source: https://en.wikipedia.org/wiki/List_of_sovereign_states_and_dependent_territories_by_population_density",
    );

  map.title().enabled(false).useHtml(true).fontSize(10);

  map.padding(0);

  var series = map.choropleth(anychart.data.set(UkData));
  series.geoIdField("id");

  series.colorScale(anychart.scales.linearColor("#deebf7", "#3182bd"));
  series.hovered().fill("#addd8e");

  series.labels(false);

  series.hovered().fill("#f48fb1").stroke(anychart.color.darken("#f48fb1"));

  series.selected().fill("#c2185b").stroke(anychart.color.darken("#c2185b"));

  map.geoData((anychart as any).maps["united_kingdom"]);
  map.contextMenu().itemsFormatter(anychartRemoveContextItems);
  map.tooltip().enabled(false);
  map.container("ukmap");
  map.draw();
};

export const UKMap = () => {
  useEffect(() => getMap(), []);
  return <div id="ukmap" style={{ width: "100%", height: "100%" }} />;
};
