import { Centaur } from "../modules/centaur/Centaur";
import { RouteItem } from "../types";

const routes: RouteItem[] = [
  {
    title: "Centaur",
    name: "Centaur",
    path: "/centaur",
    exact: true,
    component: Centaur,
  },
];

export const routesCurrentUserHasAccessTo = async (showAll?: boolean) => {
  return routes;
};

export default routes;
