import { gql } from "apollo-boost";

const GetVarietyByType = gql`
  query getVarietyByType($cropTypeID: ID!, $subVariantID: ID!) {
    getVarietyByType(cropTypeID: $cropTypeID, subVariantID: $subVariantID) {
      varietyID
      cropVarietyName
      yieldFactorUK
      yieldFactorEast
      untreatedGrainYield
      yieldFactorWest
      yieldFactorNorth
      yieldFactorSouth
    }
  }
`;
const GetVarietyHeatMapByType = gql`
  query getVarietyHeatMapByType($cropTypeID: ID!, $subVariantID: ID!) {
    getVarietyHeatMapByType(cropTypeID: $cropTypeID, subVariantID: $subVariantID) {
      varietyID
      cropVarietyName
      diseaseResMildew
      diseaseResYellow
      diseaseResBrown
      diseaseResSeptoriaNodurum
      diseaseResSeptoriaTrifici
      diseaseResEyespot
      diseaseResFusarium
      diseaseResOrangeMidge
      diseaseRhynchosporium
      diseaseNetBlotch
      diseaseBaYMV
      diseaseCrownRust
    }
  }
`;
const GetCropClasses = gql`
  query getCropClasses {
    getCropClasses {
      cropClassID
      cropClassName
    }
  }
`;
const GetCropTypes = gql`
  query getCropTypes($cropClassID: ID!) {
    getCropTypes(cropClassID: $cropClassID) {
      cropTypeID
      cropClassID
      name
    }
  }
`;
const GetVarieties = gql`
  query getVarieties($cropTypeID: ID!) {
    getVarieties(cropTypeID: $cropTypeID) {
      varietyID
      cropVarietyName
      cropTypeID
    }
  }
`;
const GetSubVariants = gql`
  query getSubVariants($cropTypeID: ID!) {
    getSubVariants(cropTypeID: $cropTypeID) {
      subVariantID
      cropTypeID
      subVariantName
    }
  }
`;
const GetVarietyProfileById = gql`
  query getVarietyProfileById($variantID: ID!) {
    getVarietyProfileById(variantID: $variantID) {
      cropVarietyName
      varietyID
      regionalScope
      yieldFactorUK
      yieldFactorNorth
      yieldFactorEast
      yieldFactorSouth
      yieldFactorWest
      untreatedGrainYield
      diseaseResMildew
      diseaseResYellow
      diseaseResBrown
      diseaseResSeptoriaNodurum
      diseaseResSeptoriaTrifici
      diseaseResEyespot
      diseaseResFusarium
      diseaseResOrangeMidge
      diseaseRhynchosporium
      diseaseNetBlotch
      diseaseBaYMV
      diseaseCrownRust
      uKBreadMaking
      uKPBreadExport
      uKBiscuitCakeMaking
      uKSWheatExport
      uKDistilling
      endospermTexture
      hagbergFallingNumber
      proteinContent
      specificWeight
      resistancetoLodging
      resistancetoLodgingwithoutPGR
      resistancetoLodgingwithtPGR
      strawLength
      stemStiffness
      stemShortness
      plantHeight
      oilContent
      glucosinolate
      earlinessofFlowering
      earlinessofMaturity
    }
  }
`;

export const YieldsGraphql = {
  GetVarietyByType,
  GetVarietyHeatMapByType,
  GetCropClasses,
  GetCropTypes,
  GetVarieties,
  GetSubVariants,
  GetVarietyProfileById,
};
