import React, { useContext, useEffect } from "react";
import { ColumnContainer, RowContainer } from "@agxio/ui-kit";
import styled from "styled-components";
import { Charts } from "./Charts";
import WeatherBack from "../../../../Assets/latestForecastBack.jpeg";
import { TempGauge } from "../../components/TempGauge";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { WeatherQueries } from "../../../../graphql/Weather";
import { CircularProgress } from "@material-ui/core";
import { DropDownsGraphql } from "../../../../graphql";
import { DropDowns } from "../../components/DropDowns";
import { RHWContainer } from "../../components/RHW";
import { CentaurContext } from "../../../centaur/store/Context";

export default () => {
  const {
    states: { selectedDropDowns },
    actions,
  } = useContext(CentaurContext);

  const { data: farmGroups, loading } = useQuery(DropDownsGraphql.GetFarmGroups);
  const [getFarms, { data: farms, loading: farmLoading }] = useLazyQuery(DropDownsGraphql.GetFarms);

  const [get5dayForecast, { data: forecastData, loading: forecastLoading }] = useLazyQuery(
    WeatherQueries.GetFiveDayWeatherForecast,
  );
  const [getHistoricalData, { data: historicalData, loading: historicalLoading }] = useLazyQuery(
    WeatherQueries.GetHistoricalData,
  );

  useEffect(() => {
    if (selectedDropDowns.farm !== -1) {
      get5dayForecast({ variables: { farmID: selectedDropDowns.farm } });
      getHistoricalData({ variables: { farmID: selectedDropDowns.farm } });
    }
  }, [selectedDropDowns, get5dayForecast, getHistoricalData]);

  useEffect(() => {
    if (selectedDropDowns.farmGroup !== -1) {
      getFarms({ variables: { farmGroupId: selectedDropDowns.farmGroup } });
    }
  }, [selectedDropDowns.farmGroup, getFarms]);

  return (
    <ColumnContainer width="100%" height="100%" align="center" justify="flex-start" padding="0">
      <RowContainer
        width="100%"
        height="50px"
        align="center"
        justify="flex-start"
        padding="0 2.5%"
        style={{ backgroundColor: "#2E2E2E" }}
      >
        <DropDowns
          farmGroups={farmGroups}
          farms={farms}
          onFarmGroupSelected={selected => {
            actions.changeSelectedDropDown("farmGroup", selected);
            actions.changeSelectedDropDown("farm", -1);
          }}
          onFarmSelected={selected => actions.changeSelectedDropDown("farm", Number(selected))}
          selectedFarm={selectedDropDowns.farm}
          selectedFarmGroup={selectedDropDowns.farmGroup}
        />
      </RowContainer>
      <RowContainer width="100%" height="90%" align="center" justify="space-between" padding="1% 2.5%">
        <Box color="black" flex="3.1">
          <Charts
            temperature={
              historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.MinTemp]) : []
            }
            rainFall={
              historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.TotalRainfall]) : []
            }
            airFrost={
              historicalData ? historicalData.getHistoricData.map((item: any) => [item.Date, item.DaysAirFrost]) : []
            }
            sunshine={
              historicalData
                ? historicalData.getHistoricData.map((item: any) => [item.Date, item.SunshineDuration])
                : []
            }
          />
        </Box>
        <Box color="#212121" flex="1" margin="0 0 0 1%">
          <TopBox align="center" justify="space-between" width="100%">
            <ColumnContainer width="100%" height="100%" align="flex-start" justify="flex-start" padding="1% 5%">
              <span>Latest Forecast</span>
              <LatestForecast width="90%" height="25%" align="top" justify="flex-start">
                <span>
                  {forecastData &&
                    forecastData.getFiveDayWeatherForecast[0] &&
                    forecastData.getFiveDayWeatherForecast[0].weatherType}
                </span>
              </LatestForecast>
            </ColumnContainer>
          </TopBox>
          <RowContainer margin="3% 0 0 0" padding="0 2%" width="100%" flex="1">
            <ColumnContainer height="100%" justify="center" align="center" flex="1">
              <span>
                Max: <br />
                {forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].feelsLikeDayTemperature}
                ° C
              </span>
              <TempGauge
                value={
                  forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].feelsLikeDayTemperature
                }
              />
            </ColumnContainer>
            <RowContainer flex="4" height="55%" margin="10% 0 0 0">
              <RHWContainer
                rain={
                  forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].precipitationProbabilityDay
                }
                humidity={
                  forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].humidityNoon
                }
                wind={
                  forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].windSpeed
                }
                windDirection={
                  forecastData &&
                  forecastData.getFiveDayWeatherForecast[0] &&
                  forecastData.getFiveDayWeatherForecast[0].windDirection
                }
              />
            </RowContainer>
          </RowContainer>
          <BottomBox>
            <label>
              <span style={{ fontWeight: "bold", color: "#80C29E" }}>Visibility:</span>{" "}
              {forecastData &&
                forecastData.getFiveDayWeatherForecast[0] &&
                forecastData.getFiveDayWeatherForecast[0].visibility}
            </label>
            <label>
              <span style={{ fontWeight: "bold", color: "#80C29E" }}>UV Index:</span>
              {forecastData &&
                forecastData.getFiveDayWeatherForecast[0] &&
                forecastData.getFiveDayWeatherForecast[0].uVIndex}
            </label>
          </BottomBox>
        </Box>
      </RowContainer>
      {(forecastLoading || historicalLoading || loading || farmLoading) && (
        <CircularProgress style={{ position: "absolute", bottom: "2%", right: "1%" }} color="primary" />
      )}
    </ColumnContainer>
  );
};

const Box = styled(ColumnContainer)`
  border: 1px solid #333333;
  background-color: ${(props: { color: string }) => props.color};
  height: 100%;
`;

const TopBox = styled(ColumnContainer)`
  width: 100%;
  flex: 1.5;
  font-size: 16px;
`;

const LatestForecast = styled(ColumnContainer)`
  background-image: url(${WeatherBack});
  background-size: 100% 100%;
  width: 100%;
  height: 90%;
  border: 1px solid white;
  justify-content: flex-end;
  padding: 2%;
  margin-top: 5%;
`;

const BottomBox = styled(ColumnContainer)`
  border-top: solid 1px #333333;
  flex: 0.7;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3%;
  width: 100%;
  background-color: #141414;
`;
