import { gql } from "apollo-boost";

const ExportData = gql`
  query exportData {
    exportData
  }
`;

export const BackupGraphql = {
  ExportData,
};
