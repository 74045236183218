import { gql } from "apollo-boost";

const GetFiveDayWeatherForecast = gql`
  query getFiveDayWeatherForecast($farmID: ID!) {
    getFiveDayWeatherForecast(farmID: $farmID) {
      weatherForecastID
      metOfficeID
      metOfficeName
      forecastDate
      windDirection
      precipitationProbabilityNight
      windGustNoon
      visibility
      humidityNoon
      weatherType
      windGustMidnight
      precipitationProbabilityDay
      uVIndex
      dayMaxTemperature
      dayMinTemperature
      feelsLikeDayTemperature
      feelsLikeNightTemperature
      humidityMidnight
      windSpeed
    }
  }
`;

const GetHistoricalData = gql`
  query getHistoricData($farmID: ID!) {
    getHistoricData(farmID: $farmID) {
      WeatherProfileHistoricID
      MetWeatherHistoryStation
      Date
      MinTemp
      MaxTemp
      DaysAirFrost
      TotalRainfall
      SunshineDuration
    }
  }
`;

const GetTexualForecast = gql`
  query getTextualForecast($farmID: ID!) {
    getTextualForecast(farmID: $farmID) {
      WeatherTextForecastID
      MetPWSTextRegionID
      DateExtracted
      Day1to2ForecastText
      Day3to5ForecastText
      Day6to15ForecastText
      Day16to30ForecastText
    }
  }
`;

export const WeatherQueries = {
  GetFiveDayWeatherForecast,
  GetHistoricalData,
  GetTexualForecast,
};
