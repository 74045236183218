import React from "react";
import { RowContainer, DropDown } from "@agxio/ui-kit";
import { pleaseSelect } from "../../../utils/dropdowns";

interface Props {
  farmGroups: any;
  farms: any;
  selectedFarm: number;
  selectedFarmGroup: number;
  onFarmSelected: (selected: number) => void;
  onFarmGroupSelected: (selected: number) => void;
}

export const DropDowns = (props: Props) => {
  return (
    <RowContainer width="100%" align="center" justify="flex-start">
      <DropDown
        label="Farm Group:"
        onSelect={selected => props.onFarmGroupSelected(Number(selected))}
        options={
          props.farmGroups
            ? [
                pleaseSelect,
                ...props.farmGroups.getFarmGroups.map((item: any) => ({
                  title: item.FarmGroupName,
                  value: item.FarmGroupID,
                })),
              ]
            : []
        }
        style={{ width: "26%" }}
        selected={props.selectedFarmGroup}
      />
      <DropDown
        label="Farm:"
        onSelect={selected => props.onFarmSelected(Number(selected))}
        options={
          props.farms
            ? [
                pleaseSelect,
                ...props.farms.getFarms.map((item: any) => ({
                  title: item.FarmName,
                  value: item.FarmID,
                })),
              ]
            : []
        }
        style={{ width: "23%", marginLeft: "1%" }}
        selected={props.selectedFarm}
      />
    </RowContainer>
  );
};
