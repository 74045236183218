import React from "react";
import styled from "styled-components";
import { ReactComponent as DataAcquisition } from "../../../Assets/icons/data_acquisition.svg";
import { ReactComponent as SensorAnalytics } from "../../../Assets/icons/sensor_analytics.svg";
import { ReactComponent as Monitor } from "../../../Assets/icons/monitor.svg";
import { ReactComponent as Configuration } from "../../../Assets/icons/configuration.svg";
import { ReactComponent as Weather } from "../../../Assets/icons/weather.svg";
import { ReactComponent as Support } from "../../../Assets/icons/support.svg";
import { ReactComponent as SensorIntelligence } from "../../../Assets/icons/sensor_intelligence.svg";
import { ReactComponent as SensorGroup } from "../../../Assets/icons/sensor_group_analytics.svg";
import { ReactComponent as SensorType } from "../../../Assets/icons/sensor_type_analytics.svg";
import { ReactComponent as Dashboards } from "../../../Assets/icons/dashboards.svg";
import { Link } from "react-router-dom";
import { CustomizableButton } from "@agxio/ui-kit";

interface IconProps {
  name: string;
  title: string;
  disabled?: boolean;
  navigateTo?: string;
}

const renderIcon = (name: string) => {
  switch (name) {
    case "Data Acquisition":
      return <DataAcquisition />;
    case "Sensor Analytics":
      return <SensorAnalytics />;
    case "Sensor Type Analytics":
      return <SensorType />;
    case "Sensor Group Analytics":
      return <SensorGroup />;
    case "Configuration":
      return <Configuration />;
    case "Monitor":
      return <Monitor />;
    case "Support":
      return <Support />;
    case "Weather":
      return <Weather />;
    case "Sensor Intelligence":
      return <SensorIntelligence />;
    case "Dashboards":
      return <Dashboards />;
    default:
      return <React.Fragment />;
  }
};

const HomeItem = (props: IconProps) => {
  const Item = () => (
    <ItemButton disabled={props.disabled}>
      <IconContainer>{renderIcon(props.name)}</IconContainer>
      <ItemText>{props.title}</ItemText>
    </ItemButton>
  );

  if (!props.disabled && props.navigateTo) {
    return (
      <Link to={props.navigateTo}>
        <Item />
      </Link>
    );
  }

  return <Item />;
};

export default HomeItem;

const ItemText = styled.p`
  font-weight: 500;
`;

const IconContainer = styled.div`
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemButton = styled(CustomizableButton)`
  width: 194px;
  height: 194px;
  background: linear-gradient(180deg, #19a355 0%, #056e34 100%);
  border: 2px solid #19a355;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px #000000;
  border-radius: 20px;
  padding: 8px 16px;
  margin: 0 5px 15px 5px;
  line-height: 1.5;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  svg path {
    fill: #031c0f;
  }
  :hover {
    background: linear-gradient(180deg, #ecf8f2 0%, #d6f2e4 100%);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 8px 12px #000000;
    border-radius: 20px;
    color: #0f9349;
  }
  :hover svg path {
    fill: #0f9349;
    cursor: pointer;
  }
  :disabled {
    background: linear-gradient(180deg, #9f9f9f 0%, #616161 100%);
    border: 2px solid #9f9f9f;
    box-sizing: border-box;
    box-shadow: 0px 8px 12px #000000;
    color: #ffffff;
  }
  :disabled svg path {
    fill: rgb(0, 0, 0, 0.8);
    cursor: none;
  }
`;
