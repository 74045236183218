import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  text: string;
}

export const Description = (props: Props) => {
  return (
    <div style={{ padding: "0 2%" }}>
      <Title>{props.title}:</Title>
      <br />
      <Desc
        dangerouslySetInnerHTML={{
          __html: props.text,
        }}
      />
      <br />
    </div>
  );
};

const Title = styled.div`
  color: #80c29d;
  background: linear-gradient(90deg, #212121 0%, #21212100 100%);
  width: 100%;
  height: 40px;
  text-align: left;
  padding: 1% 2.5%;
  font-size: 16px;
`;

const Desc = styled.div`
  color: #d4d4d4;
  padding: 0 2.5%;
`;
