import React, { createContext, useReducer, ReactNode } from "react";
import CentaurReducer from "./reducer";
import { CentaurContextType, Types, ButtonType, ActiveTabType } from "./types";

const initialState = {
  selectedDropDowns: {
    farmGroup: -1,
    sensorGroup: -1,
    sensorType: -1,
    sensor: -1,
    farm: -1,
    company: -1,
    cropClass: -1,
    cropType: -1,
    variety: -1,
    subVariant: -1,
  },
  configuration: {
    activeTab: -1,
  },
  yields: {
    activeTab: 10,
  },
};

export const CentaurContext = createContext({} as CentaurContextType);

export const CentaurProvider = ({ children }: { children: ReactNode }) => {
  const localStorageState = localStorage.getItem("state");
  const [state, dispatch] = useReducer(
    CentaurReducer,
    localStorageState ? JSON.parse(localStorageState) : initialState,
  );

  const changeSelectedDropDown = async (type: string, id: number) => {
    dispatch({
      type: Types.CHANGE_SELECTED_DROPDOWN,
      payload: {
        type,
        id,
      },
    });
  };

  const setActiveTab = (tabName: ActiveTabType, tabNo: ButtonType) => {
    dispatch({
      type: Types.SET_ACTIVE_TAB,
      payload: {
        tabName,
        tabNo,
      },
    });
  };

  return (
    <CentaurContext.Provider
      value={{
        states: state,
        actions: {
          changeSelectedDropDown,
          setActiveTab,
        },
      }}
    >
      {children}
    </CentaurContext.Provider>
  );
};
