import { Support } from "../../support/tabs";
import { Weather } from "../../weather/Weather";
import BaselineStrategy from "../tabs/BaselineStrategy";
import Configuration from "../tabs/Configuration";
import Dashboards from "../tabs/Dashboards";
import Yields from "../tabs/Yields";

const routes = [
  {
    title: "Dashboards",
    path: "/dashboards",
    component: Dashboards,
  },
  {
    title: "Yields",
    path: "/yields",
    component: Yields,
  },
  {
    title: "Baseline Strategy",
    path: "/baselineStrategy",
    component: BaselineStrategy,
  },
  {
    title: "Weather",
    path: "/weather",
    exact: true,
    component: Weather,
  },
  {
    title: "Configuration",
    path: "/configuration",
    component: Configuration,
  },
  {
    title: "Support",
    path: "/support",
    exact: true,
    component: Support,
  },
];

export default routes;
