import React, { useEffect } from "react";
import { anychartRemoveContextItems } from "../../../../utils/Anychart";

interface Props {
  temperature: any[];
  rainFall: any[];
  airFrost: any[];
  sunshine: any[];
}

let historicCharts: anychart.charts.Stock | null = null;

const createChart = (props: Props) => {
  if (historicCharts) {
    historicCharts.dispose();
    historicCharts = null;
  }

  historicCharts = anychart.stock();
  var temperature = historicCharts.plot(0);
  temperature
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  temperature.line().data(props.temperature);
  temperature.bounds(0, 0, "45%", "45%");
  temperature.title({
    text: "Temperature",
    fontColor: "white",
    fontWeight: "bold",
  });
  temperature.legend().enabled(false);
  historicCharts.scroller().selectedFill("#1e1e1e").area(props.temperature);

  var rainfall = historicCharts.plot(1);
  rainfall
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  rainfall.line().data(props.rainFall);
  rainfall.bounds("55%", 0, "45%", "45%");
  rainfall.title({
    text: "Rain Fall",
    fontColor: "white",
    fontWeight: "bold",
  });
  rainfall.legend().enabled(false);
  historicCharts.scroller().selectedFill("#1e1e1e").area(props.rainFall);

  var airFrost = historicCharts.plot(2);
  airFrost
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  airFrost.line().data(props.airFrost);
  airFrost.bounds(0, "50%", "45%", "45%");
  airFrost.title({
    text: "Air Frost",
    fontColor: "white",
    fontWeight: "bold",
  });
  airFrost.legend().enabled(false);
  historicCharts.scroller().selectedFill("#1e1e1e").area(props.airFrost);

  var sunshine = historicCharts.plot(3);
  sunshine
    .yGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .xGrid({ stroke: { color: "#ffffffaa" }, enabled: true })
    .yMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true })
    .xMinorGrid({ stroke: { color: "#ffffff88" }, enabled: true });
  sunshine.line().data(props.sunshine);
  sunshine.bounds("55%", "50%", "45%", "45%");
  sunshine.title({
    text: "Sunshine",
    fontColor: "white",
    fontWeight: "bold",
  });
  sunshine.legend().enabled(false);
  historicCharts.scroller().selectedFill("#1e1e1e").area(props.sunshine);

  historicCharts.plotsManualBounds(true);
  historicCharts.background({ fill: "black" });
  historicCharts.width("100%");
  historicCharts.tooltip(false);
  historicCharts.credits(false);
  historicCharts.contextMenu().itemsFormatter(anychartRemoveContextItems);
  historicCharts.container("chartcontainer");
  historicCharts.draw();
};

export const Charts = (props: Props) => {
  useEffect(() => createChart(props), [props]);
  return <div style={{ width: "100%", height: "100%" }} id="chartcontainer" />;
};
