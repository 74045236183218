import React from "react";
import routes from "./routes";
import { Route, RouteProps, Switch } from "react-router-dom";
import styled from "styled-components";
import { TabContainer } from "../../sharedComponents/TabContainer";
import NotAuthorized from "../../commonPages/NotAuthorized";

interface CentaurProps extends RouteProps {
  baseUrl: string;
}

export class Centaur extends React.Component<CentaurProps> {
  render() {
    return (
      <MainContainer>
        <TabContainer routes={routes} baseUrl={this.props.baseUrl} />
        <Body>
          <Switch>
            {routes.map((route, i) => (
              <Route
                key={i}
                path={`${this.props.baseUrl}${route.path}`}
                render={({ match: { url } }) => <route.component baseUrl={url} />}
              />
            ))}
            <Route component={NotAuthorized} />
          </Switch>
        </Body>
      </MainContainer>
    );
  }
}

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 45px);
  display: flex;
  flex-direction: column;
`;
