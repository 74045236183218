import { Types, CentaurState, ActiveTabType } from "./types";

export default (state: CentaurState, action: any): CentaurState => {
  switch (action.type) {
    case Types.CHANGE_SELECTED_DROPDOWN:
      localStorage.setItem(
        "state",
        JSON.stringify({
          ...state,
          selectedDropDowns: {
            ...state.selectedDropDowns,
            [action.payload.type]: Number(action.payload.id),
          },
        }),
      );
      return {
        ...state,
        selectedDropDowns: {
          ...state.selectedDropDowns,
          [action.payload.type]: Number(action.payload.id),
        },
      };
    case Types.SET_ACTIVE_TAB:
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName as ActiveTabType],
          activeTab: action.payload.tabNo,
        },
      };
    default:
      return state;
  }
};
