import React, { useContext, useEffect } from "react";
import { ColumnContainer, ListContainer, Header, ListTable, CircularLoading } from "@agxio/ui-kit";
import { YieldsGraphql } from "../../../../../graphql/Centaur/yields";
import { useLazyQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { CentaurContext } from "../../../store/Context";
import { COLORS } from "../../../../../utils/colors";

export const List = () => {
  const {
    states: { selectedDropDowns },
  } = useContext(CentaurContext);

  const [getData, { data, loading }] = useLazyQuery(YieldsGraphql.GetVarietyByType);

  useEffect(() => {
    getData({
      variables: {
        cropTypeID: selectedDropDowns.cropType,
        subVariantID: selectedDropDowns.subVariant,
      },
    });
  }, [selectedDropDowns, getData]);

  return (
    <>
      <ListContainer margin="0 0 0 5px" width="calc(75% - 250px)" height="100%" justify="space-between">
        <Header start="#19A355" end="#056E34">
          <div>List List</div>
        </Header>
        <ListTable
          data={data ? data.getVarietyByType : []}
          idColumn="varietyID"
          onSelectItem={() => {}}
          headers={[
            { title: "Variety", value: "cropVarietyName", width: "150px" },
            { title: "Benchmark", value: "benchmark", width: "150px" },
            { title: "UK Yield", value: "yieldFactorUK", width: "150px" },
            { title: "North", value: "yieldFactorNorth", width: "150px" },
            { title: "South", value: "yieldFactorSouth", width: "150px" },
            { title: "East", value: "yieldFactorEast", width: "150px" },
            { title: "West", value: "yieldFactorWest", width: "150px" },
          ]}
        />
      </ListContainer>
      <ColumnContainer margin="0 5px" width="25%" height="100%" justify="space-between">
        <DetailsContainer>
          <Header style={{ color: "white" }}>Optimum by Yield</Header>
          <DetailsBody>
            <Header style={{ color: "white" }} start="#9F9F9F" end="#616161">
              Fairing
            </Header>
            <Header style={{ color: "white" }}>Yield Analytics</Header>

            <ListItem color={COLORS.GREEN}>
              <p>Potential Yield:</p>
              <strong>85</strong>
            </ListItem>
            <ListItem color={COLORS.GREEN}>
              <p>Target Yield:</p>
              <strong>78</strong>
            </ListItem>
            <ListItem color={COLORS.GREEN}>
              <p>Value:</p>
              <strong>£17,000 /t</strong>
            </ListItem>
            <ListItem color={COLORS.GREEN}>
              <p>Stressed Yield:</p>
              <strong>90</strong>
            </ListItem>
            <ListItem color={COLORS.GREEN}>
              <p>Stressed Value:</p>
              <strong>£18,750</strong>
            </ListItem>
            <Header style={{ color: "white" }} start="#dd6969" end="#da4141">
              Risk Factors:
            </Header>
            <ListItem color={COLORS.RED}>
              <p>Yield:</p>
              <strong>0.78</strong>
            </ListItem>
            <ListItem color={COLORS.RED}>
              <p>Price:</p>
              <strong>0.93</strong>
            </ListItem>
            <ListItem color={COLORS.RED}>
              <p>Disease:</p>
              <strong>0.23</strong>
            </ListItem>
            <DetailBox>80t/ha</DetailBox>
            <DetailBox>£800/ha</DetailBox>
          </DetailsBody>
        </DetailsContainer>
      </ColumnContainer>
      {loading && <CircularLoading color="primary" />}
    </>
  );
};

const DetailsContainer = styled.div`
  background: #ffffff;
  border: 1px solid #616161;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
const DetailsBody = styled.div`
  background-color: white;
  overflow-y: auto;
  min-height: 100px;
  height: calc(100% - 42px);
`;

const ListItem = styled.div`
  display: flex;
  min-width: 200px;
  justify-content: space-between;
  padding: 4px 16px;
  margin: 4px;
  background: ${(props: { backgroundColor?: string }) => (props.backgroundColor && props.backgroundColor) || "#FFF"};
  p {
    margin: 0;
    font-weight: 100;
    font-size: 16px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #2e2e2e;
    :before {
      display: inline-block;
      margin-right: 10px;
      content: "";
      width: 30px;
      height: 14px;
      background: ${(props: { color?: string }) => (props.color && props.color) || "#FFF"};
    }
  }
  strong {
    font-weight: 600;
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #2e2e2e;
  }
`;

const DetailBox = styled.div`
  background-color: #0f9349;
  margin: 15px;
  padding: 15px;
  text-align: center;
  font-weight: bold;
`;
